import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col, Card, Nav, Carousel, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';


// import SpecialBanner from '../../images/banners/openings/usMortSpl-banner.jpg';
import CareerBanner from '../../images/banners/CurrentOpenings1-banner.jpg';
import CareerBanner2 from '../../images/banners/CurrentOpenings2-banner.jpg';

import JobBlock from '../../components/CreateOpeningBlock';

const TITLE = "CurrentOpenings - XL Dynamics Pvt. Ltd";

class CurrentOpenings extends Component {
    constructor(props){
        super(props);
        this.state = {
            error : null,
            isLoaded : false,
            openingData : [],
            openingCode : this.props.match.params.OpeningName,
            openingName: ""
        };
    }
    
    componentDidMount() {
        fetch(process.env.REACT_APP_CURRENT_OPENINGS_CDN_URL)
        .then( response => response.json())
        .then(
            // handle the result
            (result) => {
                this.setState({
                    isLoaded : true,
                    openingData : result
                });
            }
        )
    }
    render() {
        const {openingData} = this.state;
        let sortJsonArray = require('sort-json-array');
        sortJsonArray(openingData, 'openingTitle');
        const openingsList = openingData.map(Openings => {
            let title = Openings.openingTitle;
            if(!Openings.visible){
                return "";
            } else {
                return <JobBlock key={Openings.openingId} title={title} openingCode={Openings.openingCode} />;
            }
        });

        return(
            <React.Fragment>
                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>
                {/* <Carousel style={{padding:"10px"}}>
                    <Carousel.Item>
                        <img className="d-block w-100" src={CareerBanner} alt="" />
                    </Carousel.Item>
                    <Carousel.Item>
                        <Nav.Link as={Link} to="/contact-us" className="btn btn-danger btn-sm" style={{display:"unset"}}><img className="d-block w-100" src={CareerBanner2} alt="" /></Nav.Link>
                    </Carousel.Item>
                </Carousel> */}
                <Row className="removeMarginLR">
                {/* <Image className="d-block w-100" style={{padding:"10px"}} src={CareerBanner} fluid /> */}
                    <Col xs={1}></Col>
                    <Col xs={10}>
                        <h1 className="x-head" style={{height: "50px"}}>Current Openings</h1>
                        <div>
                            <h5>Take Your <span className="themeTextRed">Career</span> to the Next level with XLD!</h5><br/>
                            We are actively seeking talented, career minded, self-motivated individuals.<br/>
                            We currently have following openings:<br/>
                        </div>
                        {/* <Card> */}
                            <Card.Body>
                                <ul style={{listStyle:"none",margin:"0",padding:"0"}}>{openingsList}</ul>
                            </Card.Body>
                        {/* </Card> */}
                        <div className="text-left">
                            <h5 style={{lineHeight:"1.6"}}>Take Your <span className="themeTextRed">Career</span> to the Next level with XLD!&nbsp;&nbsp;
                            {/* <Nav.Link as={Link} to="/ApplyNow" className="btn btn-danger btn-sm" style={{display:"unset"}}>Apply Now</Nav.Link> */}
                            </h5>
                        </div>
                    </Col>
                    <Col xs={1}></Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default CurrentOpenings;