import React from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col, Image } from 'react-bootstrap';

const TITLE = "XL Dynamics India Pvt. Ltd"    
    function VerticalDatabaseManagement() {
        return(
        <React.Fragment>
           <div>
           <p style={{display: "inline"}} className="themeTextRed"><strong>Job Description</strong></p>
    
         <p>Unlock the full potential of data with XL Dynamics! We are seeking a creative and driven
          computer programmer to join our team and help us revolutionize the field of vertical database
          management.</p>

         <p>As a member of our team, you will have the opportunity to work on projects that will push
          the boundaries of what is possible with data. You will design and develop cutting-edge
          software solutions that help our clients unlock new insights and make better decisions.</p>

           <p style={{display: "inline"}} className="themeTextRed"><strong> Our ideal candidate will have:</strong>
           </p>
           <ul>
               <li>Strong programming skills and experience in the field of database management.</li>
               <li>A deep understanding of current programming languages and technologies.</li>
               <li>A creative approach to problem-solving and a passion for staying ahead of the curve.</li>
               <li>Excellent attention to detail and a desire to deliver the highest quality software solutions</li>
            </ul>
           
               <p>At XL Dynamics, we are committed to fostering a culture of innovation and collaboration.
                You will work alongside some of the brightest minds in the industry, and you will have the
                opportunity to advance your skills and your career</p>

               <p>Don't miss out on this exciting opportunity to join a dynamic and growing company. Apply
                today at <a href="https://www.xldynamics.com/careers">www.xldynamics.com/careers</a> and let's shape the future of vertical database
                management together!"</p>
               
           </div>
        </React.Fragment>
    );
}

export default VerticalDatabaseManagement;