import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Row, Col, Image, Spinner } from "react-bootstrap";
import Confetti from "react-dom-confetti";
import TextLoop from "react-text-loop";
import { Button, Grid, Card, Table } from "tabler-react";
import axios from "axios";
import FucntionsBanner from "../images/banners/Functions-banner.jpg";
import logo from "../images/functions/XLD-logo.png";
import Expandlogo from "../images/functions/XLDExpand-logo.png";
import Countdown from "react-countdown";
// import "tabler-react/dist/Tabler.css";
import rangoliData from "./Rangoli-Lucky-Draw.json";
const TITLE = "XLD Xpands Rangoli Contest";
const style = {
  drawForm: {
    width: "100%",
  },
};

const config = {
  angle: "143",
  spread: 360,
  startVelocity: 40,
  elementCount: "100",
  dragFriction: "0.15",
  duration: "6200",
  stagger: "9",
  width: "10px",
  height: "13px",
  perspective: "500px",
  colors: ["#f00", "#0f0", "#00f"],
};

class RangoliWinner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      currentItems: [],
      pastDrawnItems: [],
      showTextAnimation: true,
      removeDrawnItem: false,
      animationInterval: 150,
      showResult: false,
      disableDrawButton: false,
      value: "",
      placeholder: "Please enter the draw items here. One item per line.",
      valid: false,
      touched: false,
      validationRules: {
        minLength: 3,
        isRequired: true,
      },
      timer: true,
      winners: [],
    };

    this.getLuckyDrawData = this.getLuckyDrawData.bind(this);
    this.randomDrawItem = this.randomDrawItem.bind(this);
    this.setDelay = this.setDelay.bind(this);
    this.timerComplete = this.timerComplete.bind(this);
    this.getWinnersData = this.getWinnersData.bind(this);
  }

  componentDidMount() {
    this.getLuckyDrawData();
    if (window.location.pathname == "/rangoliwinners") {
      document.body.classList.add("refer-class");
      document.body.classList.add("body-panel");
    }
  }

  setDelay(timeOut) {
    setTimeout(() => {
      this.randomDrawItem();
    }, timeOut);
  }

  getLuckyDrawData() {
    var rangiliArray = [];
    rangoliData.map((item) => {
      rangiliArray.push(item.employeeName);
    });
    this.setState({
      ...this.state,
      items: rangiliArray,
      currentItems: rangiliArray,
    });
    console.log(rangiliArray);
  }

  getWinnersData() {
    // fetch("https://xld-cdn-/bucket1.xldynamics.com/winners-local.json", {
    fetch("https://xld-cdn-/bucket1.xldynamics.com/winners.json", {
      headers: {
        pragma: "no-cache",
        "cache-control": "no-cache",
      },
    })
      .then((response) => response.json())
      .then(
        // handle the result
        (result) => {
          var winnerArray = [];
          result.map((item) => {
            if (item.type == "rangoli") {
              winnerArray.push(item.winnerName);
            }
          });
          var winnerFilterArray = [];
          winnerFilterArray = winnerArray.filter(function (
            item,
            index,
            inputArray
          ) {
            return inputArray.indexOf(item) == index;
          });
          this.setState({
            winners: winnerFilterArray,
            pastDrawnItems: winnerFilterArray,
            timer: winnerFilterArray.length > 0 ? false : true,
          });
        }
      );
  }

  sleep = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };

  randomDrawItem() {
    const { currentItems, showTextAnimation, removeDrawnItem } = this.state;
    this.setState({
      ...this.state,
      showResult: false,
      disableDrawButton: true,
    });
    let maxItemIndex = currentItems.length;
    const randomIndex = Math.floor(Math.random() * maxItemIndex);
    this.sleep(showTextAnimation ? 3000 : 0).then(() => {
      var itemsWinner = [1, 5, 18, 6, 15];
      itemsWinner.map((num) => {
        this.setState({
          ...this.state,
          // pastDrawnItems: [
          // ...this.state.pastDrawnItems,
          // currentItems[num],
          // ],
          showResult: true,
          disableDrawButton: false,
        });
        this.getWinnersData();
      });
      // if(!this.state.pastDrawnItems.includes(currentItems[randomIndex])) {
      //     this.setState({
      //         ...this.state,
      //         // pastDrawnItems: [
      //         //   ...this.state.pastDrawnItems,
      //         //   currentItems[randomIndex],
      //         // ],
      //         showResult: true,
      //         disableDrawButton: false,
      //     });
      //     const Winners = {
      //         "type": "rangoli",
      //         "winnerName": currentItems[randomIndex]
      //     }
      //     axios.post('https://ss4vyxi4r5.execute-api.us-east-1.amazonaws.com/development/WriteJsonFile-ReferEmployee', Winners)
      //       .then(res => {
      //         console.log(res)
      //         this.getWinnersData()
      //         })
      //         .catch(function(error) {
      //             console.log("referral-Data error status " +  JSON.stringify(error));
      //         })
      // } else if(this.state.pastDrawnItems.length < 5) {
      //     this.setDelay(3000);
      // } else {
      // }
    });

    if (removeDrawnItem) {
      const copyCurrentItems = [...this.state.currentItems];
      copyCurrentItems.splice(randomIndex, 1);
      this.setState({
        currentItems: copyCurrentItems,
      });
    }
  }

  timerComplete() {
    this.setState({
      timer: false,
    });

    if (this.state.winners.length <= 0) {
      // for (var i = 1; i <= 5; i++) {
      var timeOut = 10000;
      this.setDelay(timeOut);
      // }
    }
  }

  onMount(date) {
    if (date.completed == true) {
      this.getWinnersData();
    }
  }

  render() {
    const { items, pastDrawnItems, showResult, winners } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <Helmet>
          <meta charSet="utf-8" />
        </Helmet>
        {/* <Row>
                <img className="d-block w-100" src={image} alt="First slide"/>
            </Row> */}
        <div className="container-fluid main-panel-rangoli">
          <Row>
            <img className="d-block xld-logo" src={logo} alt="First slide" />
            <img
              className="d-block expand-logo"
              src={Expandlogo}
              alt="First slide"
            />
          </Row>
          <Col md={10} className="wrapper">
            <h1 className="rangoli-title">Rangoli Contest</h1>
            <div
              className={
                this.state.timer ? "d-block wrapper-panel mt-5" : "d-none"
              }
            >
              <h2>
                <strong>
                  {" "}
                  5 Lucky Winners will be announced on Dec 5 at 5:30 PM IST{" "}
                </strong>
              </h2>
              <Countdown
                className="counter"
                date="2020-12-05T17:30:00.000+05:30"
                autoStart={true}
                onComplete={this.timerComplete}
                onMount={this.onMount.bind(this)}
              />
            </div>
            {items.length !== 0 && (
              <div
                className={`draw-block ${
                  this.state.timer ? "d-none" : "d-block"
                }`}
              >
                <Grid.Row>
                  {winners.length <= 0 ? (
                    <Grid.Col md={12} sm={12}>
                      <div className="draw-section">
                        {!showResult && items && (
                          <>
                            <TextLoop
                              className="draw-text"
                              interval={1000}
                              children={items}
                              springConfig={{ stiffness: 340, damping: 30 }}
                            />
                          </>
                        )}
                        <Confetti
                          active={this.state.showResult}
                          config={config}
                        />
                      </div>
                      {pastDrawnItems.length < 4 ? (
                        <div className="make-center text-center">
                          <Spinner animation="grow" variant="primary" />
                          <Spinner animation="grow" variant="secondary" />
                          <Spinner animation="grow" variant="success" />
                          <Spinner animation="grow" variant="danger" />
                          <Spinner animation="grow" variant="warning" />
                          <Spinner animation="grow" variant="info" />
                          <Spinner animation="grow" variant="light" />
                          <Spinner animation="grow" variant="primary" />
                          <Spinner animation="grow" variant="secondary" />
                          <Spinner animation="grow" variant="success" />
                          <Spinner animation="grow" variant="danger" />
                          <Spinner animation="grow" variant="warning" />
                          <Spinner animation="grow" variant="info" />
                          <Spinner animation="grow" variant="light" />
                          <br></br>
                          <p>
                            <h3>Drawing...</h3>
                          </p>
                          {/* // <Button
                            //     type="button"
                            //     className="btn-small btn btn-outline-success"
                            //     name="drawButton"
                            //     onClick={this.randomDrawItem.bind(this)}
                            //     disabled={disableDrawButton}
                            //     >
                            // {disableDrawButton ? "Drawing..." : "Draw"}
                            // </Button> */}
                        </div>
                      ) : (
                        ""
                      )}
                    </Grid.Col>
                  ) : (
                    ""
                  )}
                  <Grid.Col md={8} sm={8} className="make-center mt-3">
                    {pastDrawnItems.length > 0 ? (
                      <Card
                        title="Lucky Winners"
                        className="past-drawn-block"
                        body={
                          <Table>
                            <Table.Body className="past-drawn-item">
                              {pastDrawnItems.length === 0
                                ? "No previous item."
                                : pastDrawnItems.map((item, index) => (
                                    <Table.Row key={index}>
                                      <Table.Col>{item}</Table.Col>
                                    </Table.Row>
                                  ))}
                            </Table.Body>
                          </Table>
                        }
                      />
                    ) : (
                      ""
                    )}
                  </Grid.Col>
                </Grid.Row>
              </div>
            )}
            <div className="text-center mt-5 pb-3">
              <h3 className="sample-text">
                XL Dynamics grew at an amazing pace this year and expanded its
                presence across the nation.<br></br>
                XLD'ites celebrated this achievement by preparing rangoli with
                XLD Xpands theme.<br></br>
                Thanks to all the talented XLD'ites for making this Diwali a
                truly memorable one.
              </h3>
            </div>
            <Row className="p-3">
              {rangoliData.map((item) => (
                <Col xs={12} sm={12} md={6} lg={3} className="pb-2">
                  <img
                    className="d-block w-100"
                    src={item.rangoliPic}
                    alt="First slide"
                  />
                  {/* {item.employeeName} */}
                </Col>
              ))}
            </Row>
          </Col>
        </div>
      </React.Fragment>
    );
  }
}

export default RangoliWinner;
