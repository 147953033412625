import React from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col, Card, Image } from 'react-bootstrap';

// Import logos
import Excellence from '../images/Culture/Excellence.jpg';
import APlus from '../images/Culture/Aplus.jpg';
import Integrity from '../images/Culture/Integrity.jpg';
import HypTrans from '../images/Culture/Hyper_Transparency.jpg';
import LgclDcs from '../images/Culture/Logical_Decisions.jpg';
import Accountability from '../images/Culture/Accountability.jpg';
import Checklisti from '../images/Culture/Checklisti.jpg';
import model1 from '../images/Culture/model1.png';
import Elite_Only from '../images/Culture/Elite_Only.jpg';
import DAM from '../images/Culture/Direct_Access_to_Management.jpg';
import CultureBanner from '../images/banners/Culture-banner.jpg';

const TITLE = "Culture - XL Dynamics India Pvt. Ltd"

function Culture() {
    return(
        <React.Fragment>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <Row className="removeMarginLR">
                <Image className="d-block w-100" style={{padding:"10px"}} src={CultureBanner} fluid />
                <Col xs={1}></Col>
                <Col xs={10} style={{textAlign:"justify"}}>
                    <h1 className="x-head" style={{height: "50px"}}>Work Culture &#64; XLD</h1>
                    <h6 className="themeTextRed">The Name XL Dynamics means:</h6>
                    <Card.Text>Our Name &ldquo;XL&rdquo; means Excellence and &ldquo;Dynamics&rdquo; is a state of constant improvement. &ldquo;XL Dynamics&rdquo; is to constantly improve on excellence.</Card.Text>
                    
                    <h6 className="themeTextRed">Our Corporate Mission:</h6>
                    <ul>
                        <li>Find innovative ways that redefine how business is conducted.</li>
                        <li>Develop an institution where individual creativity and contribution matters.</li>
                        <li>Follow high morals and principles.</li>
                    </ul>
                    
                    <h6 className="themeTextRed">The Impetus for our core cultural framework:</h6>
                    <Card.Text>Our name and mission are the impetus behind the strong core fundamental values and the cultural framework that keeps us focused and synergized toward our goals. They contribute to consistent success, both at individual and organizational levels.<br/><br/>
                    Our culture gives us the right attitude which has led to our exponential success over the years.</Card.Text>

                    <h6 className="themeTextRed">We put the &ldquo;Customer&rdquo; First:</h6>
                    <Card.Text>&ldquo;No Employee is bigger than the Organization and No Organization is bigger than it&rsquo;s Customer.&rdquo; &ldquo;The Customer pays our Paycheck.&rdquo;<br/><br/>
                    These are the beliefs that we actively follow and implement in at our job. The Customer is always at the center of all decisions and we work towards achieving the Customer&rsquo;s objectives, while meeting our own goals.<br/><br/>
                    We know that it is the customer who truly pays our paycheck through the business they bring us and we constantly re-engineer our processes to ensure we become more efficient and accurate to offer quicker and better service to our Clients.</Card.Text>
                    
                    <h6 className="themeTextRed">Why does the customer pay our paycheck?</h6>
                    <Card.Text>The fundamental purpose of a job is that when we work, we work to serve somebody. We serve them and in return they pay us for our service.<br/><br/>
                    At XL Dynamics we&rsquo;ve made a conscious decision that we will serve Homeowners in the US, with integrity, passion and pride.</Card.Text>
                    
                    <h6 className="themeTextRed">An Act of God:</h6>
                    <Card.Text>Serving our customer is symbolic of doing God&rsquo;s Work. For example, if we create a nice software application which helps the client and promotes their business or well-being, we have delivered an Act of God.<br/><br/>
                    When we do a quality job processing a Borrower&rsquo;s loan, we help the Borrower&rsquo;s family purchase and move into his / her new home faster, at the least cost. This too is an act of God.</Card.Text>
                    
                    <h6 className="themeTextRed">Excellence:</h6>
                    <Card.Text>
                        <img width="225" className="marge_imgR rounded mobile_img" src={Excellence} alt="Generic placeholder" align="right" />
                        At XL Dynamics, we undertake Business Initiatives with a goal to accomplish them with Excellence and then to improve on it. We innovate and improve. We add value to the Businesses of our Clients / Customers, which is a distinguishing quality of the jobs we do.<br/><br/>
                        Every employee&rsquo;s excellence and consistent performance allows us the opportunity to diversify and grow to exponential proportions. We maintain an environment of openness, encourage freedom to express one&rsquo;s views and offer all employees the opportunity to take difficult and important decisions on behalf of the Organization.<br/><br/>
                        We have a <strong>Zero Tolerance for Substandard Quality</strong> of work and inculcate the principle of <strong>&ldquo;Do it right the first time&rdquo;</strong>. This expectation comes from our customers who expect and deserve nothing but the best for the service they pay for. Imagine the emotion you feel when you order a bouquet of roses to be delivered to your mother for Mother&rsquo;s Day from an online store, but the online vendor ends up sending her a bouquet of lilies whose pollen your mother is severely allergic to. Would&rsquo;t you feel extremely irate and frustrated? Would you use the online vendor again? This is the same emotion that Clients feel when they receive substandard service. Providing substandard service isn&rsquo;t prudent business and it projects &ldquo;sloppiness&rdquo; on the part of the Organization or it’s employees. Our culture in the Organization is to constantly improve on excellence which leaves no room for any level of &ldquo;sloppiness&rdquo; or substandard service.<br/><br/>
                        <img width="225" className="marge_imgL rounded mobile_img" src={APlus} alt="Generic placeholder" align="left" />
                        <strong>&ldquo;A+&rdquo;</strong> is the only <strong>Quality of Product / Service</strong> we expect and accept from our employees. Anything below this is substandard and will never be delivered to a Client / Customer. For this we train Employees to a level that they become Subject Matter Experts in the domain they handle. Each employee who has consistently imbibed and delivered &ldquo;Excellence&rdquo; within 3 years has grown to Head Business Units across the Organization or has been rewarded with remuneration beyond what the competition offers. There is always room for improvement in any area of the Business or the Organization or a Process. We believe and encourage constant improvement and innovation which keeps us up to date and gives us the competitive edge necessary for growth in the industry.<br/><br/>
                    </Card.Text>
                    
                    <h6 className="themeTextRed">Integrity, Honesty and Ethics:</h6>
                    <Card.Text>
                        To deliver Financial Services to the Mortgage Banking Industry, these values are mandatory amongst every employee at XL Dynamics. It is our Moral and Social responsibility to be honest in all our dealings, both with our Clients and Customers, as well as with each other.<br/><br/>
                        <img width="225" className="marge_imgL rounded mobile_img" src={Integrity} alt="Generic placeholder" align="left" />
                        Being Ethical is simply put, doing the right thing, always. This is practiced seriously throughout the company. We always operate within the framework of the policies enforced by Regulators, Government Sponsored Enterprises, Auditors, Investors and other Bureaus that regulate our operations. Our Managers strive hard to meet our Customers' objectives and are very successful in being able to help them profit from highly regulated and restrictive Business, where every act of indiscretion costs hundreds and thousands of Dollars.<br/><br/>
                        As an Organization, we do not tolerate any violations of our core values of Integrity, Honesty and Ethics. We ensure these values are practiced everyday at XL Dynamics and also ensure our Vendors or Third-Parties exercise them in their dealings with us.<br/><br/>
                        We also believe in not &ldquo;Covering-Up&rdquo; and accept accountability for the mistakes we make. We also follow a principle that if we make mistakes, we only make new ones.<br/>
                        <strong>After all, Bankers can&rsquo;t be Cheaters!</strong></Card.Text>
                    
                    <h6 className="themeTextRed">Hyper Transparency:</h6>
                    <Card.Text><img width="225" className="marge_imgR rounded mobile_img" src={HypTrans} alt="Generic placeholder" align="right" />
                        Hyper Transparency means voluntarily sharing one&rsquo;s mistakes and shortcomings on a task or project with others in the Team or the Company at large so all can learn from it.<br/><br/>
                    While it&rsquo;s application has great value through everyone&rsquo;s immediate learning from someone else&rsquo;s mistakes, it is difficult for the individual who presents his / her case study to reveal the flaws in the work completed by him / her. However, this transparency across all levels and designations in the company helps ensure this is the only time this mistake is made by anyone in the Organization, which is of greater importance.<br/><br/>
                    We have the courage and the humility to honestly accept mistakes amongst everyone and focus on the greater importance discussed above.</Card.Text>
                    
                    <h6 className="themeTextRed">Logical Decisions:</h6>
                    <Card.Text>
                        <img width="225" className="marge_imgR rounded mobile_img" src={LgclDcs} alt="Generic placeholder" align="right" />
                        At XL Dynamics, every employee has the responsibility and the authority to take complex and logical decisions. The responsibility and authority comes from a robust training plan which educates an employee to the level of an expert. This empowers us to logically analyze complex scenarios and take the right decision.<br/><br/>
                        A current and comprehensive Knowledge Base is also a key aid used by employees as a reference source for Policies, Procedures, Checklists and Case Studies. These are all conveniently accessible on every employee&rsquo;s desktop, for quick reference. The comprehensiveness of the Knowledge Base comes from over 10 years of experience in the Industry that is effectively documented from time to time.</Card.Text>
                    
                    <h6 className="themeTextRed">Accountability and Ownership:</h6>
                    <Card.Text><img width="225" className="marge_imgL rounded mobile_img" src={Accountability} alt="Generic placeholder" align="left" />
                        Any task is said to have been done diligently only if it is completed with Accountability and Ownership. If we do not accept accountability and ownership for what we do, we are not being honest to our customer. This leaves room for plausible deniability in all the jobs we do and in the long-run results in the loss of trust and business.<br/><br/>
                        Every employee at XL Dynamics is expected to be accountable to the stakeholder for every task that he / she accomplishes. He / she claims ownership to ensure the task is completed within the expected time-frame and with A+ quality of service. This maintains confidence among clients about the committed products and services we offer, being delivered as per the expectations.<br/><br/>
                        The individuals that do not believe in accepting accountability or ownership for the work that they do, are not meant for XL Dynamics. Such individuals believe that a company&rsquo;s primary role is to make employees feel as comfortable as possible.<br/><br/>
                        XL Dynamics and it&rsquo;s staff are successful because we know that our Job is to serve the customer and make the customer feel as comfortable as possible doing business with us.</Card.Text>
                    
                    <h6 className="themeTextRed">We say what we mean. We mean what we say:</h6>
                    <Card.Text>At XL Dynamics we do not believe in a culture of making empty commitments or false promises to our customers. If we commit to a deadline or completion of a Project, we deliver what was committed in the time-frame committed.<br/>
                    When we cannot accept a responsibility for delivery, we say no. We are not intimidated by challenging expectations and commit to something we know we cannot achieve and then hope on a prayer that it gets done. We are not ones that fear the immediate consequences of saying &ldquo;no&rdquo;. We rather state our inability and work towards being able to achieve these expectations in the most effective manner and time-frame, for us and our clients.<br/><br/>
                    This is why our clients appreciate us and trust us to get the job done.</Card.Text>
                    
                    <h6 className="themeTextRed">Stress / Pressure:</h6>
                    <Card.Text>We are passionate about delivering the best Service to the Customer. We believe in going that &ldquo;extra mile&rdquo; to make Homeownership a reality for a Borrower. Yes, we do carry the weight of being accountable, responsible and ensuring that we always deliver what we commit. That&rsquo;s what we&rsquo;re paid well for. When we&rsquo;ve accepted the customer&rsquo;s payment, we&rsquo;ve emotionally and mentally accepted that we shall proudly serve them. We&rsquo;re accountable for the delivery of the Service we&rsquo;ve been paid for and willingly challenge our abilities to meet the Customer&rsquo;s expectations.</Card.Text>
                    
                    <h6 className="themeTextRed">Following Checklists:</h6>
                    <Card.Text>XL Dynamics believes in the diligent use of Checklists in every process it follows. Each and every task performed here is done with the use of a checklist to achieve the highest standard of quality and consistency from every employee.
                        <img width="225" className="marge_imgR rounded mobile_img" src={Checklisti} alt="Generic placeholder" align="right" />
                    &nbsp;A Checklist is the result of years of process engineering and research which has gone into making the checklist precise and robust. Good performers always follow the checklist and following the checklist always ensures everyone is a good performer.<br/><br/>
                    Checklist designers are at the top level of every process and always work on making the checklist better to use and accurate.<br/><br/>
                    In an Airplane it is mandatory for a Pilot to go through and complete a comprehensive checklist in order to ensure maximum safety standards are maintained for passengers. It does not matter whether the Pilot is flying the plane for the 1st or the 100th time, he / she still needs to complete the checklist. Should this not be done, it puts the lives of hundreds of passengers at risk.<br/><br/>
                    Similarly in the Mortgage Banking Industry, it is very important to follow the checklist since a single mistake can lead to losses of up to thousands of US Dollars.<br/><br/>
                    We realize the value of our jobs because we know it&rsquo;s importance, we understand how it affects our Organization and Clients and we Profit from the rewards that quality centric, ethical and effective services bring.</Card.Text>
                    
                    <h6 className="themeTextRed">Our Model:</h6>
                    <Card.Text align="center"><img className="rounded mobile_img" src={model1} alt="Generic placeholder" /></Card.Text>
                    
                    <h6 className="themeTextRed">Type of Job:</h6>
                    <Card.Text><img width="225" className="marge_imgL rounded mobile_img" src={Elite_Only} alt="Generic placeholder" align="left" />
                    Every job at XL Dynamics requires extreme concentration. Every distraction is a potential cause for a mistake and mistakes are very expensive as they run into thousands of US Dollars and above per transaction.
                    &nbsp;We have grown from 55 Employees in the year 2008 to 800+ Employees and are still growing.<br/><br/>
                    This company is for the elite few that truly believe that in order to get anywhere, &ldquo;you have to do the ground-work before you progress to doing fancy-work&rdquo;. It is very important at XL Dynamics to know why you do what you do, to understand the bigger picture and move up the corporate ladder. The patient few that believe and practice excellence, reach high levels of expertise with a lot of success in their careers at XL Dynamics.<br/><br/>
                    We are very selective of the individuals we hire to join us. Only those serious about their careers and serious about being successful make it through. We are looking for individuals who are serious about having a privileged career where their intelligence, aptitude, education and experience would have meaningful application.<br/><br/>
                    We are looking for the next generation of employees who would create opportunities for another 3000 employees by delivering very high quality of work in remarkable time-frames to our customers.</Card.Text>
                    
                    <h6 className="themeTextRed">Direct Access to Management:</h6>
                    <Card.Text>
                        <img width="225" className="marge_imgR rounded mobile_img" src={DAM} alt="Generic placeholder" align="right" />
                        Any employee can directly approach Senior members of the Organization. The company follows an open door policy wherein any employee of the company can approach Management to have their concerns addressed. Managers and Supervisors do not sit in cabins, but rather in the work-areas with the Team Members. This makes them easily accessible and approachable. It also ensures the employees are groomed faster.
                    </Card.Text>
                    <p style={{clear:"both"}}></p>
                    
                    <h6 className="themeTextRed">Important Statistics:</h6>
                    <ul>
                        <li>Average Over Production Incentive to employees who worked on urgent projects on weekends is Rs. 35,000 to Rs. 45,000 over regular salaries + incentives. 
                            A Weekend Buffet Lunch / Dinner at a 5 Star Hotel is complementary, over and above the Incentives.</li>
                        <li>Salary of a Financial Analyst who has spent 2 years with XL Dynamics is between Rs. 14 Lakhs to Rs. 16 Lakhs p.a. These amounts do not include &ldquo;Overproduction Incentives&rdquo; which are paid over and above the income.</li>
                        {/* <li>A Loyalty Bonus of Rs. 1 Lakh is paid to employees who complete 2 Years of Service in XL Dynamics.</li> */}
                        <li>Our salaries are much higher than what competing companies pay post the annual appraisal.</li>
                        <li>Four (4) Locations in Mahape, Navi Mumbai.</li>
                        <li>Over 80 MBA Institutions and Engineering Colleges and NITs visited in 2015 for recruitment purposes.</li>
                        <li>Mega Recruitment Drives are conducted throughout India.</li>
                        <li>Salaries being offered are up to Rs. 20 Lakhs p.a.</li>
                    </ul>
                </Col>
                <Col xs={1}></Col>
            </Row>
        </React.Fragment>
    );
}
export default Culture;