import React from 'react';
import '../App.css';
import { Helmet } from 'react-helmet';
import { Row, Col, CardDeck, Card, Button, Carousel, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import HomeBanner from '../images/banners/Banner-XLD Website.jpg';
import NewYearGiveaway from '../images/banners/new-year-giveaway.jpg';
import SpecialBanner from '../images/banners/openings/usMortSpl-banner.jpg';
// import CreateCarousel from './internal-pages/Includes/CreateCarousel';

const TITLE = "Home - XL Dynamics India Pvt. Ltd"

function Home() {
    // const bannerImages = [HomeBanner, SpecialBanner];
    // const carouselGen = bannerImages.map((bannerImages) => {
    //     return <CreateCarousel key={bannerImages.id} image={bannerImages} />;
    // });
    return(
        <React.Fragment>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <Carousel indicators={false} nextIcon={false} prevIcon={false}>
                {/* <Carousel.Item>
                   <a href="https://www.linkedin.com/posts/xl-dynamics-india-pvt%2E-ltd%2E_xldmytruehome-xldmytruehome-xldynamics-activity-6879671521460178944-QYnC" target="_blank"> 
                   <img className="d-block w-100" src={NewYearGiveaway} alt="" /></a>
                </Carousel.Item> */}
                <Carousel.Item>
                    <img className="d-block w-100" src={HomeBanner} alt="Home banner" />
                </Carousel.Item>
            </Carousel>
            <Row className="removeMarginLR">
                <Col xs={1}></Col>
                <Col xs={10}>
                    <CardDeck className="home-page-card">
                        <Card border="light">
                            <Card.Header style={{border:"none",backgroundColor:"transparent",height: "50px"}} className='x-head'>
                                {/* <svg height="40" width="40">
                                    <line x1="2" y1="0" x2="40" y2="42" style={{stroke:"#E8252A",strokeWidth:4}} />
                                    <line x1="40" y1="-2" x2="0" y2="42" style={{stroke:"#E8252A",strokeWidth:5}} />
                                    Sorry, your browser does not support inline SVG.
                                    </svg>&nbsp;<span>&nbsp;&nbsp;About Us&nbsp;&nbsp;&nbsp;</span> */}
                                    About Us
                                    </Card.Header>
                            <Card.Body>
                                <p>XL Dynamics India Pvt. Ltd. was established in 2002 and incorporated in 2005, with its wholly owned office located in Navi Mumbai.</p>
                                <p>We are a privately held corporation providing IT, audit and quality control outsourcing solutions to a defined clientele in the US mortgage industry.</p>
                            </Card.Body>
                            <Card.Footer className="text-right" style={{border:"none",backgroundColor:"transparent"}}>
                                <Nav.Link as={Link} to="/AboutUs"><Button variant="danger" size="large">Learn More</Button></Nav.Link>
                            </Card.Footer>
                        </Card>
                        <Card border="light">
                            <Card.Header style={{border:"none",backgroundColor:"transparent",height: "50px"}} className='x-head'>Our Functions</Card.Header>
                            <Card.Body>
                                <p>In today's fast-moving and evolving global workplace, it is crucial for businesses to continuously streamline their processes and use cost-effective technology solutions.</p>
                                <p>We provide comprehensive solutions to the US Mortgage Banking Industry.</p>
                            </Card.Body>
                            <Card.Footer className="text-right" style={{border:"none",backgroundColor:"transparent"}}>
                                <Nav.Link as={Link} to="/Functions"><Button variant="danger" size="large">Learn More</Button></Nav.Link>
                            </Card.Footer>
                        </Card>
                        <Card border="light">
                            <Card.Header style={{border:"none",backgroundColor:"transparent",height: "50px"}} className='x-head'>Why choose <strong>XL Dynamics?</strong></Card.Header>
                            <Card.Body>
                                <p>An organization with 55 employees in 2008, currently we are a family of 800+ employees and are still growing.</p>
                                <p>When working at XL Dynamics, you work for its customer. Its customers are US Homeowners, American Families.</p>
                            </Card.Body>
                            <Card.Footer className="text-right" style={{border:"none",backgroundColor:"transparent"}}>
                                <Nav.Link as={Link} to="/why-choose-xldynamics"><Button variant="danger" size="large">Learn More</Button></Nav.Link>
                            </Card.Footer>
                        </Card>
                    </CardDeck>
                </Col>
                <Col xs={1}></Col>
            </Row>
        </React.Fragment>
    );
}

export default Home;