import React, { useState, useEffect, useRef } from 'react';
import { Form, Button } from 'react-bootstrap';
import {
    FacebookShareCount,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    WhatsappIcon,
    TelegramIcon,
    EmailIcon,
    FacebookShareButton,
    InstapaperShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    TelegramShareButton,
    EmailShareButton
} from "react-share";
import EmployeeData from './employee-ID.json'
import CsvDownload from 'react-json-to-csv';
import faceBook from '../images/facebook-share.jpg';
import axios from 'axios';

function ReferJob({ match }){
    const [value, setValue] = useState('')
    const [selected, setselected] = useState('')
    const [dataList, setdataList] = useState([])
    const [display, setDisplay] = useState(false);
    const [shareUrl, setshareUrl] = useState('');
    const [copySuccess, setcopySuccess] = useState(false)
    const [referEmpData, setReferEmpData] =useState([])
    const [selectedFile, setFileSelection] = useState(null);

    useEffect(() => {
        fetch(process.env.REACT_APP_CURRENT_OPENINGS_CDN_URL)
        .then(response => response.json())
        .then(
            // handle the result
            (result) => {
                setdataList(result)
                console.log(result)
            }
        )
    },[]);

    const {
        params: {OpeningName}
    } = match;
    const openingsList = dataList.map(oData => {
        let title = oData.openingTitle;
        if(OpeningName !== oData.openingCode && oData.visible){
            return <option key={oData.openingId} value={oData.openingCode} selected={oData.openingCode}>{title}</option>;
        }
    });

    const handleChange = event => {
        setValue(event.target.value);
    }

    const selectChange = event => {
        setselected(event.target.value)
    }
        
    const handleSubmit = event => {
            // alert('A name was submitted: ' + this.state.value);
        event.preventDefault();
        setDisplay(true)
        setshareUrl(window.location.origin+"/Opening/senior-hr-executive"+ '?emp='+ btoa(value)) //btoa for encryption
        // https://www.xldynamics.com/
        console.log(encodeURI(shareUrl))
    }

    const uploadFile = event => {
        let file = event.target.files;
        console.log(file);
        setFileSelection(file); 
    }
    

    const handleSubmitEMP = event => { 
        event.preventDefault();
        let file = selectedFile[0];
        axios("https://02tsbcu838.execute-api.us-east-1.amazonaws.com/default/getpresignedURL?file="+selectedFile[0].name)
        .then(response => {
            const urlNew = response.data.uploadURL;
            // setUrl(url)
            console.log("Recieved a signed request " + urlNew);

            var options = {
                headers: {
                'Content-Type': file.type
                }
            };
            axios({
                method: "PUT",
                url: urlNew,
                data: file,
                headers: { "Content-Type": "multipart/form-data" }
            })
            .then(result => {
                console.log("Response from s3", result)
            })
            .catch(error => {
                // alert("ERROR " + JSON.stringify(error));
                console.log("Put error status " +  JSON.stringify(error));
            })
        })
        .catch(error => {
            console.log(JSON.stringify(error));
        })
    }

    // const readFileContent = file => {
    //     const reader = new FileReader();
    //     reader.onload = (event) => {
    //       const text = event.target.result;
    //       const json = csv2json(text); // find a lib, or write one
    //       this.setState({ json });
    //     };
      
    //     reader.readAsText(file);
    //   }
    
    const generateBulkURL = event => {
        const newData = EmployeeData.map((value) => {
           return {...value, 'URL': "https://www.xldynamics.com/Opening/senior-hr-executive"+ '?emp='+ btoa(value.EmployeeCode) }
        });
       
        setReferEmpData(newData)      
    }
    console.log(encodeURI(shareUrl))
    // const shareUrl = 'http://github.com';
    const title = "XL Dynamics - HR Executive";

    // const image ='https://www.xldynamics.com/static/media/logo.c786483e.gif'
    return (
        <div className="col-lg-6 offset-lg-3">
             <h2 className="text-center pb-2">Generate Employees Reference URL</h2>
            <form onSubmit={handleSubmit} >         
                <div class="form-group">
                    <label for="exampleInputEmail1">Employee ID</label>
                    <input type="text" class="form-control" value={value} onChange={handleChange} id="exampleInputEmail1" required/>
                      {/* <small id="emailHelp" class="form-text text-muted">Employee ID Example - E1111111.</small>pattern="[E]{1}[0-9]{7}"  */}
                </div>
                {/* <div class="form-group">
                    <label>Desired Position:</label>
                    <select name="select" class="form-control" onChange={selectChange} required>
                        <option key="Select role" value="Select role" selected="Select role">Select role</option>
                        {openingsList}
                    </select>
                </div> */}
                <button type="submit" class="btn btn-primary">Generate URL</button>
                </form>
                <div className={"jumbotron card card-image mt-4" + (display ? ' d-block' : ' d-none')} style={{padding:"35px 0 0 35px"}}> 
                {/* {"item col-12" +(item.category == 'WOMEN AT XLD' ? ' woman' : '') */}
                    <div class="form-group">
                          <strong for="exampleInputEmail1">Shared URL :</strong><br></br>
                         <a href={shareUrl} target="_blank"> <label style={{fontSize:"20px", textDecoration:"underline"}} value={shareUrl}> {shareUrl}</label></a>
                    </div>
                    <br></br>
                    <div class="form-group">
                    <strong for="exampleInputEmail1">You can share directly from here :</strong>
                    <div className="Demo__container">
                        <div className="Demo__some-network">
                            <FacebookShareButton
                                url={shareUrl}
                                quote={title}
                                media={faceBook}
                                className="Demo__some-network__share-button"
                            >
                                <FacebookIcon size={52} round />
                            </FacebookShareButton>
                            <div>
                                <FacebookShareCount url={shareUrl} className="Demo__some-network__share-count">
                                {count => count}
                                </FacebookShareCount>
                            </div>
                        </div>
                        
                        <div className="Demo__some-network">
                            <LinkedinShareButton url={shareUrl} className="Demo__some-network__share-button">
                                <LinkedinIcon size={52} round />
                            </LinkedinShareButton>
                        </div>

                        <div className="Demo__some-network">
                            <TwitterShareButton
                                url={shareUrl}
                                title={title}
                                className="Demo__some-network__share-button"
                            >
                                <TwitterIcon size={52} round />
                            </TwitterShareButton>

                            <div className="Demo__some-network__share-count">&nbsp;</div>
                        </div>                        

                        <div className="Demo__some-network">
                            <WhatsappShareButton
                                url={shareUrl}
                                title={title}
                                separator=":: "
                                className="Demo__some-network__share-button"
                            >
                                <WhatsappIcon size={52} round />
                            </WhatsappShareButton>

                            <div className="Demo__some-network__share-count">&nbsp;</div>
                        </div>                                            
                        
                        <div className="Demo__some-network">
                            <TelegramShareButton
                            url={shareUrl}
                            title={title}
                            className="Demo__some-network__share-button"
                            >
                            <TelegramIcon size={52} round />
                            </TelegramShareButton>

                            <div className="Demo__some-network__share-count">&nbsp;</div>
                        </div>
                        <div className="Demo__some-network">
                            <EmailShareButton
                                url={shareUrl}
                                subject={title}
                                body="body"
                                className="Demo__some-network__share-button"
                            >
                                <EmailIcon size={52} round />
                            </EmailShareButton>
                            </div>
                    </div>
                    </div>
                </div>                                    
            
                <div className="jumbotron card card-image mt-4 d-none">
                    <button className="btn btn-md btn-warning btn-block text-uppercase" type="button" style={{left:"0", width: "100px"}} onClick={generateBulkURL}>Generate Bulk URL</button>
                    <CsvDownload 
                                    data={referEmpData}
                                    style={{ //pass other props, like styles
                                        backgroundColor: "#28a745",
                                        cursor: "pointer",
                                        fontSize:"15px",
                                        fontWeight: "bold",
                                        margin: "0 10px",
                                        display: "block",
                                        border: "1px solid #1c7430",
                                        color: "#ffffff",
                                        padding: "5px",
                                        borderRadius: "5px",
                                        position: "absolute",
                                        right:"120px"
                                    }}
                                    className="text-center"
                                    >Export to CSV</CsvDownload>
                </div >

                <div className="jumbotron card card-image mt-4 d-none">
                    <Form id="applyNow" encType="multipart/form-data" onSubmit={handleSubmitEMP} method="POST" autoComplete="off">
                        <div className="Upload">
                            <span>
                            <input type="file"
                            name="myFile"
                            onChange={uploadFile} />
                            </span>
                        </div>
                        <Button variant="danger" type="btn btn-lg submit" >Apply Now</Button>
                    </Form>
                </div>
        </div>
    );         
}
    
  export default ReferJob