import React from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col, Button, Image, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import WhyChooseXLDBanner from '../images/banners/WhyChooseXLD-banner.jpg';

const TITLE = "WhyChooseXLD - XL Dynamics India Pvt. Ltd"

function WhyChooseXLD() {
    return(
        <React.Fragment>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <Row className="removeMarginLR">
                <Image className="d-block w-100" style={{padding:"10px"}} src={WhyChooseXLDBanner} fluid />
                <Col xs={1}></Col>
                <Col xs={10} style={{textAlign:"justify"}}>
                    <h1 className="x-head" style={{height: "50px"}}>Why choose <strong className="themeTextRed">XL Dynamics</strong>?</h1>
                    XL Dynamics has dedicated its existence to serving its customers.
                    When working at XL Dynamics, you work for its customer. Its customers are US Homeowners, American Families. Every month you will fulfill the lives of 16,000 Americans from 4,000 American families who put their faith in XL Dynamics every month.
                    <p style={{clear:"both"}}></p>

                    <h6 className="themeTextRed">Its a matter of National Pride:</h6>
                    We show the world that the Indian Workforce is the best among all the Global Sectors. You definitely want the 16,000 Americans you serve to have the best experience working with your Company and your Country.
                    <p style={{clear:"both"}}></p>

                    What makes jobs at XL Dynamics important is the consistency in the dedication to excellence when serving our customers. Our jobs can easily be done by competing countries in the Global Outsourcing World like the Philippines, China and other such emerging countries. Our consistent dedication to excellence is the only motivator for customers to expand their business with us and in turn bring employment opportunities to our Country, India.
                    <p style={{clear:"both"}}></p>

                    <h6 className="themeTextRed">Continuous Learning and Development:</h6>
                    Whether its the close interaction with the senior management or providing roles with greater responsibilities and with that, greater accountability, your learning and development is one of our top priorities. With more than 90% of our employees being highly qualified MBAs, you will always have something to learn from those around you.
                    <p style={{clear:"both"}}></p>

                    <h6 className="themeTextRed">Get inspired by your colleagues:</h6>
                    We are an organization where you will be surrounded by dynamic, intelligent and young employees in the average age group of 22-27 years. This is a chance to work within a culture of camaraderie and grow your career the way you want.
                    <p style={{clear:"both"}}></p>

                    <h6 className="themeTextRed">Count On Us for Career Growth:</h6>
                    Our robust Career Power Path which ensures Employees regularly takes steps up in their career the moment they are ready. Promotions in the organization are only based on performance. You begin taking steps up in the Power Path the moment you complete 3 months in the Organization. Each step up may come with a Salary Revision, which means you could potentially have 4 Salary revisions a year. Within 2 Years an employee can begin earning salaries close to Rs. 15 – 20 Lakhs p.a.
                    <p style={{clear:"both"}}></p>

                    You are also eligible to apply for vertical growth opportunities through internal job postings on completion of 3 months since joining the organization. This means a Financial Analyst can become a Team Leader or a Subject Matter Expert (SME) after 3 months since joining a company. Our rate of promotion is one of the highest amongst the industry.
                    <p style={{clear:"both"}}></p>

                    <h6 className="themeTextRed">Our Empowered Work Place:</h6>
                    Every employee facilitates clients in processing and managing loans worth millions of dollars.
                    <p style={{clear:"both"}}></p>

                    <h6 className="themeTextRed">Employee Perks and Perquisites</h6>
                    At XL Dynamics we believe in looking after all of our employees&rsquo; needs. Here are some of the perks and perquisites all employees are entitled to:
                    <ol>
                        <li>
                            <strong>Snacks at every Employee&rsquo;s Desk at no charge:</strong><br />
                            Snacks with Coffee / Tea served to every employee thrice a day at their desks by the cafeteria personnel free of charge.
                        </li>
                        <li>
                            <strong>Buffet Lunch / Dinner at a 5 Star Hotel: </strong><br />
                            For employees who work on weekends, there is a complimentary Lunch / Dinner Buffet at a 5 star restaurant.
                        </li>
                        <li>
                            <strong>Transportation Facilities:</strong>
                            <ul>
                                <li>For Lady Employees who are at office beyond 8 PM, a special &ldquo;Priyadarshini&rdquo; cab service with lady chauffeurs are arranged to drop them home.</li>
                                <li>For Male Employees, there is a drop off facility till the nearest railway station – Ghansoli or Kopar Khairane – at night post 11 PM.</li>
                                <li>A drop home facility is provided to employees who leave after public transport ceases operation in the night.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Over-Production Incentives (over and above CTC):</strong><br />
                            For employees who work beyond their required 40 hours per week (8 hours a day, 5 days a week) or who work on weekends are eligible for special over-production incentives and weekend allowances that equals to 1.5 to 2.5 times the employee&rsquo;s salary. These allowances are much higher than the mandatory over-time salaries prescribed by the State of Maharashtra.
                        </li>
                        {/* <li>
                            <strong>Loyalty Bonuses:</strong><br />
                            Every employee accrues a Loyalty Bonus for every month spent with the company. Once the employee completes 18 months in the Organization, he / she starts receiving portions of the accrued Bonus with their Salary. Higher the employee&rsquo;s tenure, more is the Bonus Amount paid.
                        </li> */}
                        <li>
                            <strong>Exclusive Rest and Relaxation Facilities:</strong><br />
                            Employees have exclusive access to use the facilities such as Library, Gymnasium, Library and can play indoor sports such as Table Tennis, Pool, Carrom, Karaoke, Library, Foosball, Library, etc.
                        </li>
                        {/* <li>
                            <strong>Reimbursement of Hotel and Travel Expenses for Out-Station Candidates:</strong><br />
                            After confirmation, outstation candidates are provided with a 7 Day accommodation till they are able to make arrangements for their own after they move to Mumbai. Travelling expenses are also reimbursed for their travel to Mumbai.
                        </li> */}
                        <li>
                            <strong>Holidays on Birthdays and Optional Holidays:</strong><br />
                            Every Employee is entitled to avail an optional holiday on his / her Birthday. Though the company follows US Holidays due to Business requirements, employees are eligible to apply for an additional Paid Time Off on Indian Holidays and Feasts over and above their regular Paid Time Off quota.
                        </li>
                        <li>
                            <strong>Flexible Work Days:</strong><br />
                            Employees are allowed to avail Flexible work hours which can start 4.5 hours prior or after their regular shift timings once a month. This allows employees the flexibility to come in late to work or leave early before or after weekends or scheduled leaves.
                        </li>
                        <li>
                            <strong>Impromptu contests, rewards and parties:</strong><br />
                            Employees participate in impromptu parties, contests, Pizza Days, etc. which are decided at any time. Target based incentives like Ipads are announced for Top Performers whenever necessary to reward exceptional performances or achievements.
                        </li>
                        <li>
                            <strong>Sporting Events:</strong><br />
                            Annual Cricket event is organized by the company where the entire Stadium is hired for exclusive XL Dynamics tournaments. We also conduct similar events for Football and other such sports at employees&rsquo; requests.
                        </li>
                        <li>
                            <strong>Movement to a Day Shift:</strong><br />
                            Employees working in Live (Night) shift have an opportunity to move to a Day Shift after completion of 3 years of service with the company.
                        </li>
                    </ol>

                    <p style={{clear:"both"}}></p>
                    To know more about life at XLD, click on the links given below:
                    <ul>
                        <li><Nav.Link as={Link} to="/Culture" title="Life at XLD" style={{display:"inline"}} className="pr-0">Culture</Nav.Link></li>
                        <li><Nav.Link as={Link} to="/Growth" title="Life at XLD" style={{display:"inline"}} className="pr-0">Growth</Nav.Link></li>
                        <li><Nav.Link as={Link} to="/Facilities" title="Life at XLD" style={{display:"inline"}} className="pr-0">Facilities</Nav.Link></li>
                        <li><Nav.Link as={Link} to="/Testimonials" title="Life at XLD" style={{display:"inline"}} className="pr-0">Testimonials</Nav.Link></li>
                    </ul>
                    <div className="text-right">
                        <Nav.Link as={Link} to="/Careers"><Button variant="danger" size="large">&lt;&lt; Back to Careers</Button></Nav.Link>
                    </div>
                </Col>
                <Col xs={1}></Col>
            </Row>
        </React.Fragment>
    );
}

export default WhyChooseXLD;