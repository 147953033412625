import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Row, Col, Image, Card, Button } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";

import FucntionsBanner from "../images/banners/Functions-banner.jpg";
import { link } from "fs";
import '../css/table.css';

const TITLE = "Others - XL Dynamics India Pvt. Ltd";

class Others extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Others: [],
    };
    this.CSVToJSON = this.CSVToJSON.bind(this);
    this.CSVToArray = this.CSVToArray.bind(this);
  }
  componentDidMount() {
    this.getCsvData();
  }

  fetchCsv() {
    return (
      fetch(process.env.REACT_APP_CSR_URL)
        // this also do the same functionality
        // .then(response => response.text()){
        // .then(text => {
        //     return text;
        // });
        .then((response) => {
          let reader = response.body.getReader();
          let decoder = new TextDecoder("utf-8");

          return reader.read().then(function (result) {
            return decoder.decode(result.value);
          });
        })
    );
  }

  CSVToJSON(csvData) {
    var data = this.CSVToArray(csvData);
    var objData = [];
    for (var i = 1; i < data.length; i++) {
      objData[i - 1] = {};
      for (var k = 0; k < data[0].length && k < data[i].length; k++) {
        var key = data[0][k];
        objData[i - 1][key] = data[i][k];
      }
    }
    this.setState({
      Others: objData,
    });
  }

  CSVToArray(csvData, delimiter) {
    delimiter = delimiter || ",";
    var pattern = new RegExp(
      "(\\" +
        delimiter +
        "|\\r?\\n|\\r|^)" +
        '(?:"([^"]*(?:""[^"]*)*)"|' +
        '([^"\\' +
        delimiter +
        "\\r\\n]*))",
      "gi"
    );
    var data = [[]];
    var matches = null;
    while ((matches = pattern.exec(csvData))) {
      var matchedDelimiter = matches[1];
      if (matchedDelimiter.length && matchedDelimiter != delimiter) {
        data.push([]);
      }
      if (matches[2]) {
        var matchedDelimiter = matches[2].replace(new RegExp('""', "g"), '"');
      } else {
        var matchedDelimiter = matches[3];
      }
      data[data.length - 1].push(matchedDelimiter);
    }
    return data;
  }

  async getCsvData() {
    let csvData = await this.fetchCsv();
    this.CSVToJSON(csvData);
  }

  render() {
    const { Others } = this.state;
    console.log(Others);
    const OthersData = Others.map((Other) => {
      if (Other.id !== "") {
        return (
          <Card>
              <Accordion.Toggle
                as={Card.Header}
                variant="link"
                eventKey={Other.id}
              >
                {Other.headlines}
                <div className="right-panel float-right">
                {/* <strong>Date -</strong>  */}
                <span>{Other.date}</span>
                </div>
              </Accordion.Toggle>
            <Accordion.Collapse eventKey={Other.id}>
              <Card.Body>
                <div
                  dangerouslySetInnerHTML={{ __html: Other.details }}
                ></div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        );
      }
    });

    return (
      <React.Fragment>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <Row className="removeMarginLR">
          <Col xs={1}></Col>
          <Col xs={10}>
            <h1 className="x-head" style={{ height: "50px" }}>
              Others
            </h1>
            <Accordion className="main-press-panel" defaultActiveKey="1">{OthersData}</Accordion>            
          </Col>
          <Col xs={1}></Col>
        </Row>        
      </React.Fragment>
    );
  }
}

export default Others;
