import React from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col, Image } from 'react-bootstrap';
import AboutUsBanner from '../images/banners/AboutUs-banner.jpg';

const TITLE = "AboutUs - XL Dynamics India Pvt. Ltd"

function AboutUs() {
    return(
        <React.Fragment>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <Row className="removeMarginLR">
                <Image className="d-block w-100" style={{padding:"10px"}} src={AboutUsBanner} fluid />
                <Col xs={1}></Col>
                <Col xs={10}>
                    <h1 className="x-head" style={{height: "50px"}}>About Us</h1>
                    Established in 2002 and incorporated in 2005, XL Dynamics India Pvt. Ltd. is a privately held corporation providing IT, audit and quality control outsourcing solutions to a defined clientele in the US mortgage industry. Being a technology savvy organization, we have helped clients to develop integrated technology platform to bring the best of the mortgage solutions. Our experience in financial services and the mortgage banking industry, with a blend of technology and our focus on providing the highest quality financial services to our clients in US from our operation centers in India; enables us to provide the most efficient and cost effective services to our customers.
                    <p style={{clear:"both"}}></p>

                    XL Dynamics India Pvt. Ltd. assists client to develop web-enabled solution which is used for loan origination and servicing system and which is one of the most advanced technology platforms in the industry. XL Dynamics conforms to international standards to foster an efficient and contemporary work environment.
                    <p style={{clear:"both"}}></p>

                    XL Dynamics India Pvt. Ltd.&rsquo;s implementation and delivery model leverages capacity, capabilities, and competencies  to achieve excellent customer satisfaction. Being a business driven by customer success, we manage annual transactions and mortgage portfolios worth billions of US dollars.
                    <p style={{clear:"both"}}></p>
                </Col>
                <Col xs={1}></Col>
            </Row>
        </React.Fragment>
    );
}

export default AboutUs;