import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import SideBar from './components/SideMenu';
import Footer from './components/Footer';

import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

//  Import pages
import Home from './main-pages/Home';
import Functions from './main-pages/Functions';
import Culture from './internal-pages/Culture';
import Leadership from './internal-pages/Leadership';
import Growth from './internal-pages/Growth';
import Facilities from './internal-pages/Facilities';
import Testimonials from './internal-pages/Testimonials';
import Careers from './main-pages/Careers';
import ContactUs from './main-pages/ContactUs';
import AboutUs from './internal-pages/AboutUs';
import WhyChooseXLD from './internal-pages/WhyChooseXLD';
import CurrentOpenings from './internal-pages/Openings/CurrentOpenings';
import TrainingAndDevelopment from './internal-pages/T&D';
import ApplyNow from './internal-pages/ApplyNow';
import Opening from './internal-pages/Openings/Opening';
import Terms from './main-pages/Terms';
import Moments from './internal-pages/Moments';
import MeetOurPeople from './internal-pages/meetOurPeople';
import Refer from './internal-pages/refer';
import ReferEmployee from './internal-pages/Openings/referEmployee';
import LuckyWinner from './main-pages/LuckyWinner';
import RangoliWinner from './main-pages/rangoliWinner';
import Recruitment from './internal-pages/Openings/recruitment';
import Sitemap from './main-pages/Sitemap';
import PressReleases from './main-pages/PressReleases';
import Others from './main-pages/Others';

import ReferEmployeeBack from './internal-pages/Openings/referEmployeeBack'

import ScrollToTop from './components/ScrollToTop';
import CandidateKYC from './CandidateDetails/CandidateKYC';
import Dashboard from './CandidateDetails/Dashboard';

const trackingId = "UA-168674184-1";
ReactGA.initialize(trackingId);

const history = createBrowserHistory();
history.listen(location => {
  ReactGA.set({ page: location.pathname });   // Update users current page
  ReactGA.pageview(location.pathname);    // Record a pageview for the given page
});
// ReactGA.initialize('UA-131130661-8');
// ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {
  render(){
    return (  
      <React.Fragment>
        <Router history={history}>
          <ScrollToTop />
          <SideBar />
          <Switch>
            <Route exact path="/" component={Home} />
            <Redirect from="/index.html" to="/" />
            <Route path="/Functions" component={Functions} />
            <Route path="/Culture" component={Culture} />
            <Route path="/Leadership" component={Leadership} />
            <Route path="/Growth" component={Growth} />
            <Route path="/Facilities" component={Facilities} />
            <Route path="/MeetOurPeople" component={MeetOurPeople}/>
            <Route exact path="/Testimonials" component={Testimonials} />
            <Route exact path="/Careers" component={Careers} />
            <Route exact path="/contact-us" component={ContactUs} />
            <Route exact path="/AboutUs" component={AboutUs} />
            <Route exact path="/why-choose-xldynamics" component={WhyChooseXLD} />
            <Route exact path="/current-openings" component={CurrentOpenings} />
            <Route exact path="/training-and-development" component={TrainingAndDevelopment} />
            <Route exact path="/ApplyNow" component={ApplyNow} />
            <Route exact path="/ApplyNow/:OpeningName" component={ApplyNow} />
            <Route exact path="/Opening" component={Opening} />
            <Route exact path="/Opening/:OpeningName" component={Opening} />
            <Route exact path="/Terms" component={Terms} />
            <Route exact path="/Moments" component={Moments} />
            <Route exact path="/refer" component={Refer}/>
            <Route exact path="/employee-list" component={ReferEmployee} />
            <Route exact path="/diwaliwinners" component={LuckyWinner} />
            <Route exact path="/rangoliwinners" component={RangoliWinner} />
            <Route exact path="/dolphins" component={Recruitment} />
            <Route exact path="/refer-data" component={ReferEmployeeBack} />
            <Route exact path="/sitemap" component={Sitemap} />
            <Route exact path="/PressReleases" component={PressReleases} />
            <Route exact path="/Others" component={Others} />
            <Route exact path="/upload-your-kyc" component={CandidateKYC} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route render={() => 
              <h1 className="text-center">WE ARE SORRY.<br/>BUT THE PAGE YOU REQUESTED WAS NOT FOUND</h1>
            } />
          </Switch>
          <Footer />
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
