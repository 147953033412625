import React from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col } from 'react-bootstrap';

import { facilitiesCorpHQImport } from './Includes/facilitiesCorpHQImport';
import { facilityOfficeImages } from './Includes/facilitiesOfficeImport';
import { facilityRupaImages } from './Includes/facilitiesRupaImport';

import CreateLightbox from '../components/CreateLightbox';

const TITLE = "Facilities - XL Dynamics India Pvt. Ltd";

function Facilities() {

    return(
        <React.Fragment>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <Row className="removeMarginLR">
                <Col xs={1}></Col>
                <Col xs={10}>
                    <div className="x-head" style={{height: "50px"}}>Facilities @ XLD</div>
                    <h4>Corporate Headquarters</h4>
                    <CreateLightbox photos={facilitiesCorpHQImport} />
                    <div className="dropdown-divider" role="separator"></div>
                    <h4>Office Premises</h4>
                    <CreateLightbox photos={facilityOfficeImages} />
                    <div className="dropdown-divider" role="separator"></div>
                    <h4>Rupa Solitaire</h4>
                    <CreateLightbox photos={facilityRupaImages} />
                </Col>
                <Col xs={1}></Col>
            </Row>
        </React.Fragment>
    );
}

export default Facilities;