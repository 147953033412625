import React from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col, Image } from 'react-bootstrap';

const TITLE = "XL Dynamics India Pvt. Ltd"

function AIEngineer({props, openingCode }) {
    return (

        <React.Fragment>
            <ul>
                <li className="hide-list"><span style={{ display: "inline" }} className="themeTextRed"><strong>Position:</strong> </span>AI Engineer</li>
                <li><span style={{ display: "inline" }} className="themeTextRed"><strong>Job Shift:</strong> </span>Day/Mid</li>
                <li><span style={{ display: "inline" }} className="themeTextRed"><strong>Experience:</strong> </span>3 to 4 years of experience in the US Mortgage Industry</li>
                <li><span style={{ display: "inline" }} className="themeTextRed"><strong>Education:</strong></span> <span>Bachelor's degree in any field.</span></li>
                <li><span style={{ display: "inline" }} className="themeTextRed"><strong>Job Location:</strong> </span>PAN India</li>
                <li><span style={{ display: "inline" }} className="themeTextRed"><strong>Salary Range:</strong> </span>Salary will not be a constraint for the right candidate, and it may vary based on experience and skill set.</li>
            </ul>
            <div>
                <p>We are actively looking for a motivated and enthusiastic individual to join our team as an AI Engineer. In this role, you will have the opportunity to work on in-house AI projects,
                    contributing to the development of innovative solutions. While prior experience in AI is not mandatory,<strong> we will provide comprehensive training in AI Engineering. </strong>Previous experience in the US Mortgage industry is an added advantage. We welcome candidates with a strong desire to learn, grow, and contribute to our dynamic and collaborative team.
                </p>
                <p style={{ display: "inline" }} className="themeTextRed"><strong>Responsibilities:</strong></p>
                <span style={{marginLeft: 25, marginTop: 20, display: "block"}}><strong>AI Development:</strong></span>
                <ul style={{marginLeft: 30}}>
                    <li>Work on various in-house AI projects, collaborating with cross-functional teams.</li>
                    <li>Learn and apply machine learning algorithms and techniques.</li>
                    <li>Contribute to the development, testing, and deployment of AI models.</li>
                </ul>
                <span style={{marginLeft: 25, display: "block"}}><strong>Coding and Programming:</strong></span>
                <ul style={{marginLeft: 30}}>
                    <li>Participate in coding and programming activities related to AI projects.</li>
                    <li>Collaborate with other engineers to understand and implement AI algorithms.</li>
                    <li>Write clean, efficient, and maintainable code.</li>
                </ul>
                <span style={{marginLeft: 25, display: "block"}}><strong>Learning and Skill Development:</strong></span>
                <ul style={{marginLeft: 30}}>
                    <li>Actively participate in training programs to enhance AI-related coding skills.</li>
                    <li>Stay abreast of the latest advancements in AI and machine learning.</li>
                    <li>Demonstrate a keen interest in expanding knowledge in AI technologies.</li>
                </ul>
                <span style={{marginLeft: 25, display: "block"}}><strong>Collaboration:</strong></span>
                <ul style={{marginLeft: 30}}>
                    <li>Work closely with team members to understand the project requirements.</li>
                    <li>Engage in regular knowledge-sharing sessions and contribute to a collaborative work environment.</li>
                </ul>

                <p style={{ display: "inline" }} className="themeTextRed"><strong>Skills:</strong></p>
                <ul>
                    <li>Enthusiastic about working with AI projects.</li>
                    <li>Strong communication and collaboration skills.</li>
                    <li>Professionalism and discipline in meeting project deadlines.</li>
                    <li>Ability to work well in a team environment.</li>
                    <li>Eagerness to learn and adapt to new technologies.</li>
                </ul>

                <p style={{ display: "inline" }} className="themeTextRed"><strong>Training and Development:</strong></p>
                <ul>
                    <li>Comprehensive training will be provided in AI programming and related technologies.</li>
                    <li>Opportunities for continuous learning and skill development.</li>
                    <li>Mentorship from experienced AI engineers.</li>
                </ul>

                <p style={{ display: "inline" }} className="themeTextRed"><strong>Requirements:</strong></p>
                <ul>
                    <li>Bachelor's or above degree in any field.</li>
                    <li>Basic understanding of programming concepts.</li>
                    <li>Eagerness to learn and apply AI and machine learning concepts.</li>
                    <li>Strong problem-solving skills and attention to detail.</li>
                </ul>
                {  props.location.search.length > 0 ? <></> : <>
                <p style={{ display: "inline" }} className="themeTextRed"><strong>Note:</strong></p>
                <ul>
                    <li>Salary will not be a constraint for the right candidate, and it may vary based on experience and skill set.</li>
                    <li>Candidates who have appeared for our selection process in the last 3 months are not eligible for this opening.</li>
                    <li>Candidates with a notice period of more than 60 days (non-negotiable)are requested not to apply.</li>
                </ul></>}
            </div>
        </React.Fragment>
    )
}

export default AIEngineer;