import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

function CreateLightbox(props){
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    }

    return(
        <React.Fragment>
            <Gallery photos={props.photos} onClick={openLightbox} />
            <ModalGateway>
            {viewerIsOpen ? (
                <Modal onClose={closeLightbox}>
                <Carousel
                    currentIndex={currentImage}
                    views={props.photos.map(x => ({
                    ...x,
                    srcset: x.srcSet,
                    caption: x.title
                    }))}
                />
                </Modal>
            ) : null}
            </ModalGateway>
        </React.Fragment>
    );
}

export default CreateLightbox;