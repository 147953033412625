import React from 'react';
import { Nav, Card, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';


function Terms() {
    return(
        <Card.Body fluid="true" className="entry-content">
            <Row>
                <Col xs={12}>
                    <p className="x-head" style={{height: "50px"}}>Terms</p>
                    <p>Please read these TERMS AND CONDITIONS OF USE carefully. By using this website, you hereby agree to these TERMS AND CONDITIONS OF USE. If you do not agree to these TERMS AND CONDITIONS OF USE, please do not use this site. XL Dynamics India Pvt. Ltd. reserves the right at its discretion to change these TERMS AND CONDITIONS OF USE at any time without notice. Your continued use of this website after the change of TERMS AND CONDITIONS OF USE shall constitute your acceptance to agree to any such changes.</p>
                    <ul>
                        <li><strong>COPYRIGHT</strong></li>
                        <p>All information displayed, transmitted or carried on the XL Dynamics India Pvt. Ltd. (including, but not limited to, text, photographs, news articles, opinions, reviews, trademarks, service marks and the like, collectively the “Content”) is protected by international copyright and other intellectual property laws. The Content is owned by XL Dynamics India Pvt. Ltd., its affiliates or third party licensors. Any use of the Content, including but not limited to, modification, distribution, reproduction, republication, transmission, posting, reposting, transfer, uploading, duplication or selling without the prior written permission of XL Dynamics India Pvt. Ltd. is strictly prohibited. You may make a single copy of the Content displayed on XL Dynamics India Pvt. Ltd. for your personal, informational, noncommercial offline use only, provided the Content are not modified in any way, all copyright and other notice on any copy are retained. The use of any such Content on any other website or network computer environment is prohibited.</p>
                        <li><strong>DISCLAIMER</strong></li>
                        <p>XL Dynamics India Pvt. Ltd. disclaims all warranties, express or implied, including but not limited to, implied warranties of merchantability, fitness for a particular purpose. XL Dynamics India Pvt. Ltd. does not represent or warrant that materials on this website are accurate, complete, reliable, current, or error-free. XL Dynamics India Pvt. Ltd. does not represent or warrant that this website or its server(s) are free of viruses or other harmful components.</p>
                        <li><strong>LIMITATION OF LIABILITY</strong></li>
                        <p>In no event shall XL Dynamics India Pvt. Ltd., its affiliates, licensors, employees, agents or contractors be liable to you for any damages or losses, including but not limited to, direct, indirect, special, consequential, incidental or punitive damages resulting from or caused by this website, its public posting web boards, its content or any errors or omissions in its content, even if advised of possibility of such damages.</p>
                        <li><strong>HYPERLINKS</strong></li>
                        <p>As a service to users of the XL Dynamics India Pvt. Ltd., we include links to other site and XL Dynamics India Pvt. Ltd. has no control over and makes no representations of any kind regarding the content or appropriateness of content on such sites, and you hereby irrevocably waive any claim against us with respect to such sites.</p>
                        <li><strong>SUBMISSIONS (by email, forms)</strong></li>
                        <p>You acknowledge that by submitting messages or materials, including but not limited to, posting or email, to XL Dynamics India Pvt. Ltd., you automatically grant XL Dynamics India Pvt. Ltd. a perpetual, royalty-free, irrevocable, non-exclusive right and license to use, reproduce, modify, adapt, publish, translate, create derivative works from and distribute such materials or incorporate such materials into any form.</p>
                        {/* <li><strong>REGISTRATION AND PASSWORD</strong></li>
                        <p>To use customer area at XL Dynamics India Pvt. Ltd., you must register with XL Dynamics India Pvt. Ltd. on the form provided. You agree to provide true, accurate, current and complete information about yourself as prompted by XL Dynamics India Pvt. Ltd.’s registration form and to maintain and promptly update the information you provide to keep it true, accurate, current and complete. If XL Dynamics India Pvt. Ltd. has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, XL Dynamics India Pvt. Ltd. has the right to suspend or terminate your account and refuse any and all current or future use of Web Board(s).</p>
                        <p>To use customer area at XL Dynamics India Pvt. Ltd., you will need a user name and password, which will receive through the registration process. You are responsible for maintaining the confidentiality of password and account, and are responsible for all usage of account by any third party authorized by you to use your account. Any fraudulent, abusive, or otherwise illegal activity may be grounds for termination of your account, at XL Dynamics India Pvt. Ltd.’s sole discretion.</p>
                        <p>You agree to notify XL Dynamics India Pvt. Ltd. immediately of any unauthorized use of your password or account or any other breach of security. XL Dynamics India Pvt. Ltd. will not be liable for any loss or damage arising from your failure to protect your password or account information.</p> */}
                        <li><strong>TERMINATION</strong></li>
                        <p>XL Dynamics India Pvt. Ltd. may, in its sole discretion, terminate, cancel or suspend your right to access to all or part of the service at XL Dynamics India Pvt. Ltd. for any reason, at any time without notice. Upon termination, you must destroy all materials obtained from this site, and all copies thereof, whether made under this TERMS AND CONDITIONS OF USE or otherwise.</p>
                        <li><strong>MISCELLANEOUS</strong></li>
                        <p>1. XL Dynamics India Pvt. Ltd. reserves the right to change, remove or modify any and all content contained on this website at any time without notice.<br/>
                        2. You agree to report any copyright violation of the TERMS AND CONDITIONS OF USE to XL Dynamics India Pvt. Ltd. as soon as you become aware of them. In the event you have a claim of copyright infringement with respect to material that is contained in XL Dynamics India Pvt. Ltd. website, please notify us. (<Nav.Link as={Link} to="/contact-us" className="pr-0 pl-0" style={{display:"inline"}}>Contact us</Nav.Link>)</p>
                        <li><strong>EQUAL OPPORTUNITY EMPLOYER</strong></li>
                        <p>XL Dynamics India is an Equal Opportunity Employer. All applications submitted through this website are screened and considered solely on the basis of merit. XL Dynamics India does not promote or allow discrimination on the basis of age, gender, race, color, nationality, religion, disability, socio-economic background or such other factors. XL Dynamics India does not support or promote child labor and we do not have any child labors employed with the organization.
                        </p>
                    </ul>
                </Col>
            </Row>
        </Card.Body>
    );
}

export default Terms;