export const momentImages = [
    { src: require('../../images/Moments/1.jpg'), width: 3, height: 2 },
    { src: require('../../images/Moments/2.jpg'), width: 4, height: 2 },
    { src: require('../../images/Moments/3.jpg'), width: 3, height: 2 },
    { src: require('../../images/Moments/4.jpg'), width: 3, height: 2 },
    { src: require('../../images/Moments/5.jpg'), width: 3, height: 2 },
    { src: require('../../images/Moments/6.jpg'), width: 3, height: 2 },
    { src: require('../../images/Moments/7.jpg'), width: 3, height: 2 },
    { src: require('../../images/Moments/8.jpg'), width: 3, height: 2 },
    { src: require('../../images/Moments/9.jpg'), width: 3, height: 2 },
    { src: require('../../images/Moments/10.jpg'), width: 3, height: 2 },
    { src: require('../../images/Moments/11.jpg'), width: 3, height: 2 },
    { src: require('../../images/Moments/12.jpg'), width: 3, height: 2 },
    { src: require('../../images/Moments/13.jpg'), width: 3, height: 2 }
]