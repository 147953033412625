import React from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col, Image } from 'react-bootstrap';
import CustomJournalistPost from './CustomJournalistPost';
import CorporateTrainer from './CorporateTrainer';
import VerticalDatabaseManagement from './VerticalDatabaseManagement';
import CreativeProfile from './CreativeProfile';
import AIEngineer from './AIEngineer';
const TITLE = "XL Dynamics India Pvt. Ltd"

function CustomPost({props, openingCode}) {
    return(
        <React.Fragment>
            {openingCode == "corporate-communications" ?
           <div>
           <p style={{display: "inline"}} className="themeTextRed"><strong>Unleash the magic within you, Inspire with your ideas and Build a better world</strong></p>
           <ul>
               <li>Does writing bring you joy? </li>
               <li>Are you obsessed with quality and always go that extra mile?</li>
               <li>Do you have strong writing, editing, and research skills?</li>
           </ul>
           <p>Then here’s an amazing chance for you to grow personally and professionally.</p>
           <p style={{display: "inline"}} className="themeTextRed"><strong>
            What does this opportunity offer?</strong>
           </p>
           <ul>
               <li>Sharp learning curve. Master the entire process of content creation from conceptualization till publishing.</li>
               <li>Collaborate with some of the smartest, most creative and nicest people in the business.</li>
               <li>Come as you are and become who you want to be. Write your own success story by customizing and choosing your own career path. </li>
               <li><span style={{display: "inline"}} className="themeTextRed"><strong>Salary Range:</strong> </span>Up to Rs.10,00,000 p.a. Salary is not a constraint for the right candidate.</li>
           </ul>
           <p><strong>Experience and education in any relevant field such as a literature, teaching and foreign language is preferred. However, note that we care less about experience or education. We look for passion.</strong></p>
           <p>We have multiple opportunities across various departments such as internal communications, brand development, content production, employee engagement, and event marketing. We will assign you a role as per your experience, expertise and interest.</p>
           <p style={{display: "inline"}} className="themeTextRed"><strong>What we are looking for?</strong></p>
           <ul>
               <li>Thinkers who can conceptualize, source, consolidate, articulate, communicate and present ideas in a persuasive and easy-to-comprehend way.</li>
               <li>Continuous learners who are passionate about communications and committed to doing great work.</li>
               <li>Writers who have immaculate grammar, editing and proofreading skills. Ones who can use every tool in their creative arsenal to write content that commands attention and loved by readers.</li>
               <li>Professionals who can bring positivity and enthusiasm to every assignment, build and maintain strong rapport with teams, and ensure that the final deliveries are always on time and meet the creative expectations.</li>
           </ul>
           <p>If you meet the above brief, we want to hear from you.</p>
           <p><span style={{display: "inline"}} className="themeTextRed"><strong>Shift:</strong> </span>Night</p>
           <p><span style={{display: "inline"}} className="themeTextRed"><strong>Job Location:</strong> </span>Navi Mumbai, Mumbai, Bengaluru, Kolkata, Pune, Noida, Ahmedabad, Jaipur, Indore, Lucknow, Mysuru, Kochi, Chennai, Guwahati.</p>
           </div> 
           : openingCode == "corporate-trainer" ? <CorporateTrainer/> 
           : openingCode == "vertical-database-management-officer" ? <VerticalDatabaseManagement/>
           : openingCode == "Creative-head" || openingCode == "Creative-lead" ? <CreativeProfile openingCode={openingCode}/> 
           : openingCode == "AI-Engineer" ? <AIEngineer props={props} openingCode={openingCode}/> : <CustomJournalistPost />}
        </React.Fragment>
    );
}

export default CustomPost;