import React from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col, Image } from 'react-bootstrap';

// Import images
import formula from '../images/Growth/money_formula.png';
import performance from '../images/Growth/performance_report.png'
import GrowthBanner from '../images/banners/Growth-banner.jpg';

const TITLE = "Growth - XL Dynamics India Pvt. Ltd"

function Growth() {
    return(
        <React.Fragment>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <Row className="removeMarginLR">
                <Image className="d-block w-100" style={{padding:"10px"}} src={GrowthBanner} fluid />
                <Col xs={1}></Col>
                <Col xs={10} style={{textAlign:"justify"}}>
                    <h1 className="x-head" style={{height: "50px"}}>Growth @ XLD</h1>
                    <blockquote>
                        <p className="quote">
                            Watch your potential soar at XL Dynamics. If you&rsquo;re looking to launch a career in finance, excel at it @ XL Dynamics. If you are looking for just a simple job, our opportunities would overwhelm you and leave you behind.
                        </p>
                    </blockquote>
                    <p style={{clear:"both"}}></p>
                    
                    <h6 className="themeTextRed">Good English Reading and Writing Skills and Basic Mathematics Skills are all you need:</h6>
                    <p>The only skills you need to begin a successful career at XL Dynamics are good English Reading and Writing Skills and Basic Mathematics skills. All other aspects of your Professional Development are what XL Dynamics trains and coaches you through the Career Power Path that you would follow once you are on board.</p>
                    <p style={{clear:"both"}}></p>
                    
                    <h6 className="themeTextRed">Initial Training:</h6>
                    <p>Every new comer to XL Dynamics is given basic training about understanding the Mortgage Industry. This forms the basis for all future process training since it acquaints the employee with the Industry he / she would be closely working in everyday at XL Dynamics.</p>
                    <p style={{clear:"both"}}></p>
                    <p>Every employee is evaluated on his / her knowledge and understanding daily and is moved on to Process Training within a week to 10 days from the joining date. Simple expectations are set with new employees and the employee is finally rated on his / her:</p>
                    <ol>
                        <li>Honesty</li>
                        <li>Accountability</li>
                        <li><span style={{fontWeight:"400"}}>Customer Service or Customer-centric Skills</span></li>
                        <li>Communication Skills</li>
                        <li>Basic Algebra</li>
                        <li>Ability to accurately and diligently use a checklist</li>
                    </ol>
                    <p style={{clear:"both"}}></p>
                    
                    <h6 className="themeTextRed">Process Training:</h6>
                    <p>The Process Training is designed in every function where learning is incremental and accomplished in small steps. Thus, employees learn a portion of the process thoroughly, work on active work based on what they are trained on and then once they are ready, learn a little more complex aspect about the process. The best minds in the Finance Industry are available to Train you.</p>
                    <p style={{clear:"both"}}></p>
                    <p>Thus every employee is able to be productive and is eligible to earn incentives on the work they do in very short intervals. When employees meet the expectations set for movement to the next function, they are automatically moved forward for incremental training and are now able to handle both, what they are currently trained on and the new process or complex function they learn.</p>
                    <p style={{clear:"both"}}></p>
                    
                    <h6 className="themeTextRed">Career &ldquo;Power Path<b>&rdquo;</b>:</h6>
                    <p>XLD has laid out its Career Management Model to provide complete control to employees to decide their own career growth. There are seven levels of growth that every employee would progress through, each giving him / her growth in responsibility, complex decision making and also a revision in compensation.</p>
                    <p style={{clear:"both"}}></p>
                    <p>The Power Path is divided into small steps to impart intensive learning to an employee at each and every step, which helps him or her to become a master in that particular field. The Power Path helps fully trained and high performing employees to move from one process to another and progress in their learning of newer, diverse and complex functions.</p>
                    <p style={{clear:"both"}}></p>
                    <p>It starts with simple functions and moves to Management of Properties, Quality Control, Portfolio Valuations, Working Capital Management and so on. In two years an individual will be a Mortgage Industry expert and will be taking important decisions on behalf of the company.</p>
                    <p style={{clear:"both"}}></p>
                    <p>The Company centrally tracks and monitors performance in terms of Efficiency, Quality and HR aspects. Eligible Employees will be approached for a transition to the next level.</p>
                    <p style={{clear:"both"}}></p>
                    <p>The moment the employee moves to next level / function, a revision in Salary is also be applicable. Every employee gets multiple opportunities for salary revisions in one year based on his / her performance. An employee who performs reasonably well and meets expectations could have up to 4 salary revisions in 1 year.</p>
                    <p style={{clear:"both"}}></p>
                    <p>The goal is to assess performance and reward it immediately. Employees who are not able to perform well in the new responsibility, simply move back to the previous function they were good at. Once they are ready, they can once again opt to transition to the complex responsibility.</p>
                    <p style={{clear:"both"}}></p>
                    <p>Employees also have the flexibility of choosing to remain at a certain level or function as they are content with the work they do. In such cases, they would remain within the same process / function till they choose to move to a complex one.</p>
                    <p style={{clear:"both"}}></p>
                    <p>To qualify for a movement to a complex responsibility:</p>
                    <ol>
                        <li>An employee must achieve the target productivity with zero errors for 2 months.</li>
                        <li>An employee must demonstrate the right work ethic and a commitment to achieving superior quality, in turn helping our Client achieve its objectives.</li>
                        <li>An employee must be Honest, Accountable and Professional.</li>
                        <li>An employee is expected to deliver the highest quality of work in all tasks assigned within the designed time frame.</li>
                    </ol>
                    <p style={{clear:"both"}}></p>
                    
                    <h6 className="themeTextRed">No Politics – We love everyone:</h6>
                    <p>XL Dynamics strongly advocates &ldquo;No Politics&rdquo; at the workplace. No matter how much an employee gets along with his / her supervisor, this would never be the basis for anyone&rsquo;s career progression.</p>
                    <p style={{clear:"both"}}></p>
                    <p>XL Dynamics loves every employee equally and rewards only based on performance.</p>
                    <p style={{clear:"both"}}></p>
                    
                    <h6 className="themeTextRed">Early Responsibilities:</h6>
                    <p>If an employee shows the desired performance, he / she can reach their next career level, i.e. become a Supervisor with 6 months to a year from their joining date.</p>
                    <p style={{clear:"both"}}></p>
                    <p><i><strong>&ldquo;A winning team is built by winners. The best thrive at XL Dynamics&rdquo;</strong></i></p>
                    <p style={{clear:"both"}}></p>
                    
                    <h6 className="themeTextRed">We make Money, we play Hard too:</h6>
                    <p>At XL Dynamics our time is Business. We&rsquo;re here to make Money for ourselves and for our company. We work voluntarily on Weekends when needed. We also Party hard when we&rsquo;re away from work. The money that we earn helps us give privileged lives and opportunities to our families. We&rsquo;re able to afford homes and luxuries with the money we make for the good quality of work we deliver.</p>
                    <p style={{clear:"both"}}></p>
                    <p>We take our careers seriously.</p>
                    
                    <h6 className="themeTextRed">We share our Profits:</h6>
                    <p>At XL Dynamics, profits are shared with High Performing Employees who contribute to the growth of the Company. Making money at XL Dynamics isn&rsquo;t difficult. We&rsquo;ve made the Process of earning money into simply following checklists. The simple formula to earn a lot of money at XL Dynamics is:</p>
                    <p style={{clear:"both"}}></p>
                    <p style={{textAlign:"center"}}><img src={formula} alt="Earning Formula" className='img-fluid' /></p>
                    <p style={{clear:"both"}}></p>
                    <p>So you keep working for the customer, keep following simple Step by Step checklists, Read and Write well in English and perform simple calculations, you will keep earning more and more money. The more checklists you follow accurately, the better you get at your job, the more money you will earn and succeed.</p>
                    <p style={{clear:"both"}}></p>
                    <p>The smarter employees who simplify and improvise checklists (money-makers) to make them more efficient, earn even more money as Certified Checklist Authors.</p>
                    <p style={{clear:"both"}}></p>
                    <p><strong>Here are examples of how our employees have shared our profits as on June 2016:</strong></p>
                    <ol>
                        <li><strong>5</strong> employees at XLD have <strong>earned Rs. 4,50,000/- in the last three months over and above their CTCs</strong>.</li>
                        <li><strong>30 employees</strong> have <strong>earned more than Rs. 1,00,000/- as incentives over and above their CTCs in the last three months.</strong></li>
                        <li><strong>40</strong> employees have <strong>earned double their monthly CTC salaries</strong> in the last 3 months.</li>
                        <li><strong>63</strong> employees have earned between <strong>Rs. 50,000</strong> and <strong>Rs. 1,00,000</strong> as incentives every month for the last 3 months.</li>
                        <li>Over <strong>150 Employees</strong> earned Special Incentives and Over Production Incentives (OPI) of over <strong>Rs. 50,000/-</strong> per month in <strong>February 2016</strong> and <strong>March 2016.</strong></li>
                        <li>Over <strong>300 Employees</strong> earned <strong>Rs. 20,000/-</strong> as Special Incentives over and above their salaries, incentives and over production incentives for coming to office on a Weekend to assist customers.</li>
                    </ol>
                    <p style={{clear:"both"}}></p>
                    <p>Our employees are thus able to afford privileged lives for themselves and their families.</p>
                    <p style={{clear:"both"}}></p>
                    
                    <h6 className="themeTextRed">Job Security:</h6>
                    <p>XL Dynamics hires, encourages, promotes and secures futures of Individuals who are Good Performers. Our expectations are that we all work efficiently, don&rsquo;t make mistakes and add value to the Business of our clients in whatever we do.</p>
                    <p style={{clear:"both"}}></p>
                    <p>Being successful at XL Dynamics isn&rsquo;t that hard. You walk in, get trained for a short period, perform, move to a higher level in the pyramid, perform and keep moving up in the Pyramid. Anyone who is dedicated, works hard and is good in English can do it.</p>
                    <p style={{clear:"both"}}></p>
                    <p>When the stakes are this high and the expectations are clear, low performers feel threatened about their performances and job stability. We work with our weakest links and give them the tools and training they need to become successful. For those that do, they no longer feel insecure, become good performers and have a bright future ahead of them. The rest move out in search of conducive environments where quality and performance are not paramount.</p>
                    <p style={{clear:"both"}}></p>
                    <p>Unethical employees or those who are not interested in delivering good customer service are weeded out from the system. Honest, Customer Centric and high performing employees succeed rapidly.</p>
                    <p style={{clear:"both"}}></p>
                    
                    <h6 className="themeTextRed">Competition:</h6>
                    <p>It&rsquo;s our experience and perseverance that helps us achieve goals that many find unfathomable. We&rsquo;re all excelling in our Businesses and at our careers at XL Dynamics. We are strong and tough when we compete, but are very transparent and fair. Our sincere coaches and leaders are determined to see us succeed.</p>
                    <p style={{clear:"both"}}></p>
                    <p>After all, it&rsquo;s in our DNA to compete, we&rsquo;re only human. We don&rsquo;t deny that we&rsquo;re competitive, we embrace it. It is the essence of our lives. We encourage it and we prosper. We&rsquo;re the winning team.</p>
                    <p style={{clear:"both"}}></p>
                    <p>We look at obstacles as opportunities to deliver better than our competition, who generally shy away from adversity. That&rsquo;s what sets us apart and helps us grow exponentially. Creating 900+ jobs in an industry known more for layoffs and shutdowns is testament to our commitment.</p>
                    <p style={{clear:"both"}}></p>
                    
                    <h6 className="themeTextRed">Wall Street / IIMs, here we come:</h6>
                    <p>Wall Street has two aspects to it: Equity and Fixed Income.</p>
                    <p style={{clear:"both"}}></p>
                    <p>We operate in the second aspect, i.e. Fixed Income. This is 50% of Wall Street, but in terms of the volume of business generated, accounts for 80% of what Wall Street operates on. Those of us that work for 5 to 10 years in the company know the ground level for conducting business on Wall Street.</p>
                    <p style={{clear:"both"}}></p>
                    <p>Our Clients need people who know Fixed Income, Finance and can Manage Money. Once you know about Fixed Income, you gain a new perspective on how the World works, about why there are Foreclosure practices and why Fixed Income drives World Politics.</p>
                    <p style={{clear:"both"}}></p>
                    <p>Anyone who has worked at XL Dynamics for 2 years in Finance knows what an IIM Graduate knows.</p>
                    <p style={{clear:"both"}}></p>
                    
                    <h6 className="themeTextRed">Working in Live Shifts:</h6>
                    <p>Working in Live (Night) Shift, we get opportunities to interact directly with the client. This is a very good learning experience as we know the pulse of the work that we do and understand the customer&rsquo;s response immediately. This gives us the competitive edge of learning directly from various industry experts in the domain for over 10 – 15 years&rsquo; experience.</p>
                    <p style={{clear:"both"}}></p>
                    
                    <h6 className="themeTextRed">Performance Reports:</h6>
                    <p>Performance is tracked on a Daily Basis at XL Dynamics. Through Daily Performance Report every employee has a clear understanding of his / her daily work assignments and also how many units of work were accomplished or left behind at the end of the day. The snapshot below show the achievements as of November 16, 2015 where performance items needing immediate attention can easily be called out and addressed through the color controls.</p>
                    <p style={{clear:"both"}}></p>
                    <p style={{textAlign:"center"}}><img src={performance} alt="Earning Formula" className='img-fluid' /></p>
                    <p style={{clear:"both"}}></p>
                    <p>Even one error in the month is a cause of concern and is immediately identified and reported through the color controls. Similarly, one unit of work that is pending is a cause of concern. We expect that no work is ever left behind at the end of the day and no error is expected or tolerated from any trained resource. Though an employee may complete their work before leaving, his / her efficiency is monitored to identify help that he / she needs to be able to complete tasks in time. This is to ensure employees do not spend long hours in the office and leave on time.</p>
                    <p style={{clear:"both"}}></p>
                    <p>The expected daily productivity of employees is 8 Hours, from a 9 hour work-day. Those who are efficient in their work often over-achieve this and earn over-production incentives. These reports are shared with all staff within a team or process which helps encourage healthy competition among our staff to outperform and thus grow faster.</p>
                </Col>
                <Col xs={1}></Col>
            </Row>
        </React.Fragment>
    );
}

export default Growth;