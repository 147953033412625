import React from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col, Image } from 'react-bootstrap';

const TITLE = "XL Dynamics India Pvt. Ltd"

function CustomJournalistPost() {
    return(
        <React.Fragment>
           <div>
           <p style={{display: "inline"}} className="themeTextRed"><strong>Job Description</strong></p>
           <ul>
               <li>Do you have a passion for reporting news?</li>
               <li>Are you a curious lifelong learner who thrives by sharing knowledge?</li>
               <li>Can you translate complex concepts into simple, engaging, high quality and trustworthy pieces?</li>
           </ul>
           <p>Then here’s an amazing chance for you to grow personally and professionally.</p>
           <p style={{display: "inline"}} className="themeTextRed"><strong>
           We are looking for a journalist with strong reporting experience and exceptional research and writing skills who can:</strong>
           </p>
           <ul>
               <li>Cover a wide range of topics related to the U.S. economy, finance, real estate, and technology.</li>
               <li>Gather news and information from various sources to identify great stories and trends.</li>
               <li>Produce a steady stream of news, stories, analysis and exclusive insights in different formats (press releases, blogs, reports, videos, podcasts, FAQs, social media posts, etc.) that appeal to a general audience as well as sophisticated financial readers. </li>
               <li>Bring clarity to complicated topics and offer creative ideas for making critical information accessible.</li>
               <li>Work strategically with an eye toward drawing in new readers and increasing our audience over time.</li>
            </ul>
           <p style={{display: "inline"}} className="themeTextRed"><strong>This role offers you a chance to:</strong></p>
           <ul>
               <li>Immerse yourself in an environment full of learning and development opportunities.</li>
               <li>Sit at the intersection of big themes that touch markets and economies in the U.S.</li>
               <li>Go big and tell stories that start conversations and set agendas for the decision-makers.</li>
               <li>Work with multiple teams to produce compelling journalism.</li>
               <li>Shape the future of mortgage industry.</li>
           </ul>
           <p><span style={{display: "inline"}} className="themeTextRed"><strong>Salary Range:</strong> </span>Salary is not a constraint for the right candidate.</p>
           <p>If you meet the above brief, we want to hear from you.</p>
           <p><span style={{display: "inline"}} className="themeTextRed"><strong>Shift:</strong> </span>Day / Night</p>
           <p><span style={{display: "inline"}} className="themeTextRed"><strong>Job Location:</strong> </span>Navi Mumbai, Mumbai, Bengaluru, Kolkata, Pune, Ahmedabad, Jaipur, Indore, Lucknow, Kochi, Guwahati, Delhi NCR (Gurugram).</p>
           </div>
        </React.Fragment>
    );
}

export default CustomJournalistPost;