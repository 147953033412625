import React, { Component } from 'react';
import axios from 'axios';
import { Row, Col, Jumbotron, Container, Form, Button, Modal, Image, Toast, Spinner, Nav, Card } from 'react-bootstrap';
import './CandidateKYC.css';

const TITLE = "CurrentOpenings - XL Dynamics Pvt. Ltd";

const loginEmail = 'admin'
const loginPassword = "xld!@#$"
class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            email:"",
            password:"",
            visiblePanel: false,
            candidates: [],
            warningToast: false, 
            toastMsg: "",
            loader: false,
            filterText: null
        };
        this.handleEmailChange = this.handleEmailChange.bind(this)
        this.handlePasswordChange = this.handlePasswordChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.signOut = this.signOut.bind(this)   
        this.getCandidateData = this.getCandidateData.bind(this)
        this.renderTableData = this.renderTableData.bind(this)
        this.renderTableHeader = this.renderTableHeader.bind(this)
        this.checkMimeType = this.checkMimeType.bind(this)
        this.maxSelectFile = this.maxSelectFile.bind(this)
        this.checkFileSize = this.checkFileSize.bind(this)
        this.updateCandidateData = this.updateCandidateData.bind(this)
        this.filerbyText = this.filerbyText.bind(this)
    }

    componentDidMount() {  
        this.getCandidateData()      
        if (localStorage.getItem('email') == loginEmail && localStorage.getItem('password') == loginPassword) {
            this.setState({
                visiblePanel: true
            })
        }
        this.setState({
            error: false
        })
    }

    handleEmailChange(event) {
        this.setState({ email: event.target.value });
    }

    handlePasswordChange(event) {
        this.setState({ password: event.target.value });
    }

    handleSubmit(e) {
        e.preventDefault();
        localStorage.setItem('email', loginEmail)
        localStorage.setItem('password', loginPassword)
        if (loginEmail == this.state.email && loginPassword == this.state.password) {
            this.setState({
                visiblePanel: true
            })
        } else {
            this.setState({
                error: true
            })
        }
    }

    signOut() {
        localStorage.removeItem('email');
        localStorage.removeItem('password');
        this.setState({
            visiblePanel: false,
            error: false
        })
    } 
    
    renderTableHeader() {
        return (
            <tr>
                <th>ID</th>
                <th>Date</th>
                <th>Email, Aadhaar Name</th>
                <th>Resume</th>
                <th>Pan Card</th>
                <th>Aadhaar Card</th>
                <th>Photo</th>
                <th>Passport / Police Verification</th>
                <th>Address</th>
                <th>Affidavit</th>
                <th>Wifi Receipt</th>
                <th>Post. Graduation</th>
                <th>Post. Graduation cert.</th>
                <th>Graduation</th>
                <th>Graduation cert.</th>
                <th>Diploma/HSC</th>
                <th>SSC Certificate</th>
                <th>Experienced</th>
                <th>Offer / Appointment Letter</th>
                <th>Relieving / Resignation Letter</th>
                <th>Experience Letter</th>
                <th>Salary received by</th>
                <th>Salary Slip</th>
                <th>Employment 2</th>
                <th>Offer / Appointment Letter 2</th>
                <th>Relieving / Resignation Letter 2</th>
                <th>Experience Letter 2</th>
                <th>Salary received by</th>
                <th>Salary Slip 2</th>
            </tr>
        )
     }

    renderTableData() {
        return this.state.candidates.map((candidate, index) => {
           const { id, date,email, aadharCardName, resumeFile, aadharCardFile, panCardFile, addressProofFile, photoFile,
            passportOrPoliceFile, nameChageAffidavitFile, wifiReceiptFile, postGraduationStatus,
            postGratuationFile, graduationStatus, graduationFile, DiplomaHSCFile, SSCFile, experiencedStatus,
            offerorAppoinmentLetterFile1, relievingLetterFile1, experienceLetterFile1, salaryReceiveBy1, salarySlipFile1, EmployeerStatus2, 
            offerorAppoinmentLetterFile2, relievingLetterFile2, experienceLetterFile2, salaryReceiveBy2, salarySlipFile2 } = candidate //destructuring
           return (
              <tr key={id}>
                 <td>{index + 1}</td>
                 <td>{date}</td>
                 <td>{email}, <br/> {aadharCardName}</td>
                 <td><a target="_blank" href={resumeFile}>Resume </a></td>
                 <td>{panCardFile != "" ? <a target="_blank" href={panCardFile}> Pan Card </a>  :  
                    <Form.Control type="file" className="column-width" onChange={this.onChangeHandler.bind(this, email)} id="panCardFile" />                 
                 }</td>
                <td>{aadharCardFile != "" ? <a target="_blank" href={aadharCardFile}>Aadhaar Card </a>  :  
                    <Form.Control type="file" className="column-width" onChange={this.onChangeHandler.bind(this, email)} id="aadharCardFile" />                 
                }</td>
                <td>{photoFile != "" ? <a target="_blank" href={photoFile}>Photo File </a>  :  
                    <Form.Control type="file" className="column-width" onChange={this.onChangeHandler.bind(this, email)} id="photoFile" />                 
                }</td>
                <td>{passportOrPoliceFile != "" ? <a target="_blank" href={passportOrPoliceFile}>Passport / Police Copy </a>  :  
                    <Form.Control type="file" className="column-width" onChange={this.onChangeHandler.bind(this, email)} id="passportOrPoliceFile" />                 
                }</td>
                <td>{addressProofFile != "" ? <a target="_blank" href={addressProofFile}>Address Proof </a>  :  
                    <Form.Control type="file" className="column-width" onChange={this.onChangeHandler.bind(this, email)} id="addressProofFile" />                 
                }</td>
                <td>{nameChageAffidavitFile != "" ? <a target="_blank" href={nameChageAffidavitFile}>Affidavit File </a>  :  
                    <Form.Control type="file" className="column-width" onChange={this.onChangeHandler.bind(this, email)} id="nameChageAffidavitFile" />                 
                }</td>
                <td>{wifiReceiptFile != "" ? <a target="_blank" href={wifiReceiptFile}>Wifi Receipt </a>  :  
                    <Form.Control type="file" className="column-width" onChange={this.onChangeHandler.bind(this, email)} id="wifiReceiptFile" />                 
                }</td>
                <td>{postGraduationStatus == 'Yes' ? 'Yes' : postGraduationStatus == 'No' ? 'No' : postGraduationStatus == 'Pursuing' ? 'Pursuing' : ''}</td>
                <td>{postGratuationFile != "" ? <a target="_blank" href={postGratuationFile}>Post Graduation Cert. </a>  :  
                    <Form.Control type="file" className="column-width" onChange={this.onChangeHandler.bind(this, email)} id="postGratuationFile" />                 
                }</td>
                <td>{graduationStatus == 'YesG' ? 'Yes' : graduationStatus == 'NoG' ? 'No' : graduationStatus == 'PursuingG' ? 'Pursuing' : ''}</td>
                <td>{graduationFile != "" ? <a target="_blank" href={graduationFile}>Graduation Cert. </a>  :  
                    <Form.Control type="file" className="column-width" onChange={this.onChangeHandler.bind(this, email)} id="graduationFile" />                 
                }</td>
                <td>{DiplomaHSCFile != "" ? <a target="_blank" href={DiplomaHSCFile}>Diploma/HSC Cert. </a>  :  
                    <Form.Control type="file" className="column-width" onChange={this.onChangeHandler.bind(this, email)} id="DiplomaHSCFile" />                 
                }</td>
                <td>{SSCFile != "" ? <a target="_blank" href={SSCFile}>SSC Certificate </a>  :  
                    <Form.Control type="file" className="column-width" onChange={this.onChangeHandler.bind(this, email)} id="SSCFile" />                 
                }</td>
                <td>{experiencedStatus}</td>
                <td>{offerorAppoinmentLetterFile1 != "" ? <a target="_blank" href={offerorAppoinmentLetterFile1}>Offer / Appointment Letter </a>  :  
                   <Form.Control type="file" className="column-width" onChange={this.onChangeHandler.bind(this, email)} id="offerorAppoinmentLetterFile1" />
                }</td>
                <td>{relievingLetterFile1 != "" ? <a target="_blank" href={relievingLetterFile1}>Relieving / Resignation Letter </a>  :  
                   <Form.Control type="file" className="column-width" onChange={this.onChangeHandler.bind(this, email)} id="relievingLetterFile1" />
                }</td>
                <td>{experienceLetterFile1 != "" ? <a target="_blank" href={experienceLetterFile1}>Experience Letter </a>  :  
                   <Form.Control type="file" className="column-width" onChange={this.onChangeHandler.bind(this, email)} id="experienceLetterFile1" />
                }</td>
                <td>{salaryReceiveBy1 == 'NetBanking' ? 'Net Banking / In Account' : salaryReceiveBy1 == 'CashInHand' ? 'Cash In hand / Cheque' : ''}</td>
                <td>{salarySlipFile1 != "" ? <a target="_blank" href={salarySlipFile1}>Salary Slip </a>  :  
                   <Form.Control type="file" className="column-width" onChange={this.onChangeHandler.bind(this, email)} id="salarySlipFile1" />
                }</td>
                <td>{EmployeerStatus2 == 'PrevYes' ? 'Yes' : EmployeerStatus2 == 'PrevNo' ? 'No' : ''}</td>
                <td>{offerorAppoinmentLetterFile2 != "" ? <a target="_blank" href={offerorAppoinmentLetterFile2}>Offer / Appointment Letter 2 </a>  :  
                   <Form.Control type="file" className="column-width" onChange={this.onChangeHandler.bind(this, email)} id="offerorAppoinmentLetterFile2" />
                }</td>
                <td>{relievingLetterFile2 != "" ? <a target="_blank" href={relievingLetterFile2}>Relieving / Resignation Letter 2 </a>  :  
                   <Form.Control type="file" className="column-width" onChange={this.onChangeHandler.bind(this, email)} id="relievingLetterFile2" />
                }</td>
                <td>{experienceLetterFile2 != "" ? <a target="_blank" href={experienceLetterFile2}>Experience Letter 2</a>  :  
                   <Form.Control type="file" className="column-width" onChange={this.onChangeHandler.bind(this, email)} id="experienceLetterFile2" />
                }</td>
                <td>{salaryReceiveBy2 == 'NetBankingSecond' ? 'Net Banking / In Account' : salaryReceiveBy2 == 'CashInHandSecond' ? 'Cash In hand / Cheque' : ''}</td>
                <td>{salarySlipFile2 != "" ? <a target="_blank" href={salarySlipFile2}>Salary Slip 2</a>  :  
                   <Form.Control type="file" className="column-width" onChange={this.onChangeHandler.bind(this, email)} id="salarySlipFile2" />
                }</td>
              </tr>
           )
        })
    }

    checkMimeType(event){
        //getting file object
        let files = event.target.files 
        //define message container
        let err = []
        // list allow mime type
        const types = ['image/png', 'image/jpeg', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
        
        // loop access array
        for(var x = 0; x<files.length; x++) {
            // compare file type find doesn't matach
            if (types.every(type => files[x].type !== type)) {
                // create error message and assign to container   
                err[x] = 'File format is not supported for upload.';
            }
        };
        for(var z = 0; z<err.length; z++) {// if message not same old that mean has error 
            // discard selected file
            this.setState({
                toastMsg: err[z]
            })
            event.target.value = null;
            this.setState({
                warningToast: true
            })
        }
        return true;
    }
    
    maxSelectFile(event) {
        let files = event.target.files
        if (files.length > 1) { 
            this.setState({
                toastMsg: 'Only 1 file can be uploaded at a time'
            })
            event.target.value = null;
            this.setState({
                warningToast: true
            })
            return false;
        }
        return true;
    }

    checkFileSize(event) {
        let files = event.target.files
        let size = 2000000 
        let err = []; 
        for(var x = 0; x<files.length; x++) {
            if (files[x].size > size) {
                err[x] = files[x].type+' is too large, please select a file less than 2mb';                
            }
        };
        for(var z = 0; z<err.length; z++) {// if message not same old that mean has error 
            // discard selected file
            // alert(err[z]);
            this.setState({
                toastMsg: err[z]
            })
            event.target.value = null;
            this.setState({
                warningToast: true
            })
            return false;
        }
        return true;
    }

    onChangeHandler = (email, event) => {
        var files = event.target.files;
        if(this.maxSelectFile(event) && this.checkMimeType(event) && this.checkFileSize(event)) {
            this.setState({
                loader: true
            })
            let file_id = event.target.id;
            let file_reader = new FileReader();
            let file = event.target.files[0];

            var fileParts = files[0].name.split('.')
            var fileDate = fileParts[0].replace(/\s/g, '')+Date.now();
            var fileName = fileDate+"."+fileParts[1]
            var updateArray = []
            file_reader.onload = () => {
                updateArray = [{
                    file_id: file_id,
                    file_name: fileName,
                    file_object: file_reader.result
                }]
                this.updateCandidateData(file_id, updateArray, email);
                // console.log('************', selectedFile)
            };
            file_reader.readAsDataURL(file);
        }
        if(files.length > 0){
            this.setState({
                warningToast: false
            })
        }
    }

    updateCandidateData(file_id, updateArray, email) {
        if (updateArray && updateArray.length > 0) {
            axios.post("https://ynyoui2fz5.execute-api.us-east-1.amazonaws.com/prod/python-s3-multiplefile-upload-api", updateArray)
                .then(response => {
                    console.log('API response', response);
                    const apiResponse = response.data.URL[0].URL;
                    const candidateFile = {
                        "method": "update",
                        "columnName": file_id,
                        "URL": apiResponse,
                        "email": email
                    }                    
                    axios.post('https://v5fpflyj6j.execute-api.us-west-1.amazonaws.com/prod/candidate-kyc-save', candidateFile)
                        .then((response) => {
                            console.log(response.data);
                            this.getCandidateData();
                        }, (error) => {
                            console.log(error);
                        });
                })
        }
    }

    
    handleErrorClose = () => {
        this.setState({
            warningToast: false
        })
    }

    getCandidateData(filterText=this.state.filterText){
        var myHeaders = new Headers();
        myHeaders.append('pragma', 'no-cache');
        myHeaders.append('cache-control', 'no-cache');
        const candidateInput = {
            "method": "get",
            headers: myHeaders,
            // "limit": this.state.limit, 
            // "offset": this.state.offset,
            "filterText": filterText
        }
        axios.post('https://v5fpflyj6j.execute-api.us-west-1.amazonaws.com/prod/candidate-kyc-save', candidateInput)
            .then((response) => {
                console.log(response.data);
                fetch(response.data.url)
                .then( response => response.json())
                .then(
                    // handle the result
                    (result) => {
                        this.setState({
                            candidates : result,
                            loader: false
                            // filterData: result,
                            // pageCount: result.length > 0 ? Math.ceil(result[0].totalRecords / this.state.limit) : 0
                        });
                    }
                )
            }, (error) => {
                console.log(error);
            });            
    }

    filerbyText = (event) => {
        console.log(event)
        this.state.filterText = event.target.value != "" ? event.target.value : null        
        this.getCandidateData(this.state.filterText);
    };

    render() {
        const { error, warningToast, toastMsg, visiblePanel, loader } = this.state;
        return (
            <div className="table-responsive text-nowrap p-5" >
                <h2 className="text-center pb-2 col-md-12">List of Candidate KYC </h2>
                <div className={`row ${visiblePanel ? 'd-none' : 'd-block'}`}>
                    <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                        {
                            error ?
                                <div className="alert alert-danger" role="alert">
                                    Please login with valid username and password
                                </div> : ''
                        }

                        <div className="card card-signin my-5">
                            <div className="card-body">
                                <h5 className="card-title text-center">Sign In</h5>
                                <form className="form-signin" onSubmit={this.handleSubmit}>
                                    <div className="form-label-group">
                                        <input type="text" id="email" className="form-control" value={this.state.email} onChange={this.handleEmailChange} placeholder="Email address" required autofocus />
                                        <label for="email">User Name</label>
                                    </div>

                                    <div className="form-label-group">
                                        <input type="password" id="password" className="form-control" value={this.state.password} onChange={this.handlePasswordChange} placeholder="Password" required />
                                        <label for="password">Password</label>
                                    </div>
                                    <button className="btn btn-lg btn-danger btn-block text-uppercase" type="submit">Sign in</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"row col-md-12 " + (visiblePanel ? ' d-block' : ' d-none')}>
                    <div className="row col-md-12">
                        <button className="btn btn-md btn-danger btn-block text-uppercase col-md-2 col-sm-12 p-2 mb-5 mt-3" type="button" onClick={this.signOut}>Sign out</button>
                        <div className=" row make-center border p-2 col-md-10 mb-5 mt-3">
                                <input type="text" id="txtFilter" placeholder="Search with Email, Full Aadhaar Name and click outside to perform search" className="form-control" onBlur={this.filerbyText}></input>
                                {/* <span>Show entries</span> */}
                        </div>
                    </div>
                    { loader &&
                    <div style={{textAlign:"center"}} >                        
                        <Spinner animation="grow" variant="primary" />
                        <Spinner animation="grow" variant="secondary" />
                        <Spinner animation="grow" variant="success" />
                        <Spinner animation="grow" variant="danger" />
                        <Spinner animation="grow" variant="warning" />
                        <Spinner animation="grow" variant="info" />
                        <Spinner animation="grow" variant="light" />
                    </div>}
                    <table id='candidate'>
                        <tbody>
                            {this.renderTableHeader()}
                            {this.renderTableData()}
                        </tbody>
                    </table>
                    
                </div>

                <Modal size='md' centered show={warningToast} backdrop="static">
                    <Modal.Body> {toastMsg} </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleErrorClose.bind(this)}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default Dashboard;