export const facilityRupaImages = [
    { src: require('../../images/Facilities/Rupa Solitaire/1.jpg'), width: 3, height: 2 },
    { src: require('../../images/Facilities/Rupa Solitaire/2.jpg'), width: 4, height: 2 },
    { src: require('../../images/Facilities/Rupa Solitaire/3.jpg'), width: 3, height: 2 },
    { src: require('../../images/Facilities/Rupa Solitaire/4.jpg'), width: 3, height: 2 },
    { src: require('../../images/Facilities/Rupa Solitaire/5.jpg'), width: 3, height: 2 },
    { src: require('../../images/Facilities/Rupa Solitaire/6.jpg'), width: 3, height: 2 },
    { src: require('../../images/Facilities/Rupa Solitaire/7.jpg'), width: 3, height: 2 },
    { src: require('../../images/Facilities/Rupa Solitaire/8.jpg'), width: 3, height: 2 },
    { src: require('../../images/Facilities/Rupa Solitaire/9.jpg'), width: 3, height: 2 },
    { src: require('../../images/Facilities/Rupa Solitaire/10.jpg'), width: 3, height: 2 }
]