import React, { Component } from 'react';
import { Container, Nav, Card, Row, Col, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../css/footer.css';

class Footer extends Component {
    constructor(props){
        super(props);
        this.state = {
            error : null,
            isLoaded : false,
            openingData : []
        };
    }
    
    componentDidMount() {
        fetch(process.env.REACT_APP_CURRENT_OPENINGS_CDN_URL)
        .then( response => response.json())
        .then(
            // handle the result
            (result) => {
                this.setState({
                    isLoaded : true,
                    openingData : result
                });
            }
        )
    }
    
    render() {
        const {openingData} = this.state;
        const openingAd = openingData.map(Openings => {
            if(Openings.footerAd) {
                return <Carousel.Item className="w-100" key={Openings.openingId}>
                        <Nav.Link as={Link} to={"/Opening/"+Openings.openingCode}><h3>{Openings.openingTitle}</h3></Nav.Link>
                    </Carousel.Item>
            }
        });
        return(
            <Container fluid="true" className="pt-3">
                <Row className="footer-carousel">
                    <Col xs={12} className="">
                        <div className="tag_line container_main"><p>The Platform for Today - The Pathway to Tomorrow</p></div>
                        <div className="w-100">
                            <Col xs={12} className="text-center">
                                <Carousel fluid="true" className="fc-links">
                                    {openingAd}
                                    <Carousel.Item className="w-100">
                                        <Nav.Link as={Link} to="/Moments"><h3>XLD's Mega Recruitment Drive Moments</h3></Nav.Link>
                                    </Carousel.Item>
                                </Carousel>
                            </Col>
                        </div>
                    </Col>
                </Row>
                <Card.Body>
                    <div className="footer_nav mobile_width">
                         <ul>
                            {/* <Nav.Link as={Link} to="/sitemap">Sitemap</Nav.Link> |  */}
                            <Nav.Link as={Link} to="/Terms">Terms</Nav.Link>
                        </ul>
                    </div>
                    <div className="copyright mobile_width">&copy; {new Date().getFullYear()} XL Dynamics India Pvt. Ltd. All Rights Reserved</div>
                </Card.Body>
            </Container>
        );
    }
}

export default Footer;