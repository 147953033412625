import Carousel from 'react-bootstrap/Carousel'
import React, { useState, useEffect  } from 'react';
import {peopleVideo} from './Includes/meetPeople'

function ControlledCarousel() {
    const [index, setIndex] = useState(0);
  
    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };
  
    return (
      <Carousel activeIndex={index} onSelect={handleSelect} interval={150000} className="app col-md-12 col-sm-12">
        {
           peopleVideo.map( (item, key)=>{
              return  <Carousel.Item >
                        <div className="nav">
                            <a className="active"><span> {item.category}</span></a>
                        </div>
                        {
                            item.info.map((emp) =>{
                                return(
                                    <div className="video-block col-md-3 col-sm-6">
                                        <a href={emp.video} target="_blank"> <img src={emp.src} /> </a> 
                                        <strong>{item.category != 'WOMEN AT XLD' ? emp.employee : ''}</strong>
                                        <span>{item.category != 'WOMEN AT XLD' ? emp.team : ''}</span>
                                    </div>
                                    )
                            })
                        }                   
                </Carousel.Item>
            })
        }        
      </Carousel>
    );
}
  
export default ControlledCarousel;