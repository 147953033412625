import Carousel from 'react-bootstrap/Carousel'
import React, { useState, useEffect  } from 'react';

function RecruitmentCarousel() {
    const [index, setIndex] = useState(0);
    const bannerImages = [              
        {src: require('../../images/banners/Slider1.png')},
        {src: require('../../images/banners/Slider2.png')},
        {src: require('../../images/banners/Slider3.png')}
    ];  
    return (
        <Carousel indicators={false}>
            {
                bannerImages.map((item) => {
                    return <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={item.src}
                        alt="First slide"
                        />       
                  </Carousel.Item>
                  
                })
            }
        
      </Carousel>
    );
}
  
export default RecruitmentCarousel;